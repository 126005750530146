*{
    margin:0;
    padding:0;
    text-decoration: none;
}

main{
    width: 100%;
    
}
.sidebar{
    background: #8081ff;
    color: #fff;
    height: 100%;
    width: 250px;
    margin-left: 0px;
    transition: all 0.5s;
    padding-top: 20px; 
    
    
} 
.top_section{
    display: flex;
    align-items:center;
    padding:20px 0px 20px 30px ;
}
.logo{
    font-size: 22px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.linked{
    display: flex;
    color: #fff;
    padding: 4px 12px;
    gap: 10px;
    transition: all 0.5s;
    text-decoration: none;
    
}

.linked:hover{
    color: #000;
    transition: all 0.5s;
}
.linked.active{
    background: whitesmoke;
    color: #000;
    padding: 5px 16px;
}
.icon,.link_text{
    font-size: 16px;
    list-style-type: none;
    text-decoration: none;
    display: flex;
   
}


.link_text{
    line-height: 46px;
    width: 200px;
}
.menu_list{
    position: fixed;
    z-index: 2000;
}


.link{
  display: flex;
  color: #fff;
  padding: 2px 12px;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover{
  color: #fff;
  transition: all 0.5s;
  background-color: rgba(240, 248, 255, 0.193);
}
.link.active{
  background: whitesmoke;
  color: #000;
}

.link .icon{
 /* margin-top: 10px;*/
}
/*===dropdown css===*/

.nav .dropdown ul {
    display: block;
    position: absolute;
    left: 60px;
    top: 0px;
    margin: 0;
    padding: 5px 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
    list-style-type: none;
    margin-top: -50px;
  }
  
  .nav .dropdown ul li {
    min-width: 250px;
  }
  
   .dropdown ul a {
    font-size: 14px;
    text-transform: none;
    font-weight: 500;
    color: #000;
  }
  
  .nav .dropdown ul a .icon {
    font-size: 12px;
  }
  
  .nav .dropdown ul a:hover,
  .nav .dropdown ul .active:hover,
  .nav .dropdown ul li:hover>a {
    color: #8081ff;
  }
  
  .nav .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
   .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
   .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .dropdown .dropdown ul {
      left: -90%;
    }
  
     .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }

