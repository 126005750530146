.image-element{
    display: flex;
    width: 100%;
    img{
        &.img-fluid{
            max-width: 100%;
            height: auto;
            &.width-full{
                width: 100%;
            }
        }

        
    }
    
}