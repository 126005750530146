/*=====instructor Dashboard start=======*/
.page-bg{
    background-color:#f4f9f9;
        background-size:fill
}

.instructor-bg-details{
    background-color:#988583;
    height:320px;
    border-bottom:30px solid #f4acb7
}

.instructor-img{
    width:120px;
    height:120px;
    border-radius:50%;
   object-fit:contain;
   align-items: center;
   margin-top: 60px;
}
.btn-start{
    border-radius:5px;
     font-size:12px;
     background-color: #4361ee;
     color: #fff;
}
.btn-start:hover{
    background-color: #4361ee;
    color: #fff;
}
.instructor-card{
    border:none;
    box-shadow:0px 0px 8px #d3d3d3;
    border-radius: 5px;
}
.module-img{
    width:180px;
    height:120px;
    object-fit:fill
}
.module-playicon{
    position:absolute;
    justify-content:center;
    top:30px;
    left:90px;
    color:#ffacc5;
    z-index:1
}
.module-title{
    line-height:100px;
    margin-left:20px
}
.user-icon{
margin-right:5px
}
.instructor-title{
    margin-top:-10px;
    color:#023e8a
}

/*=====instructor Dashboard end=======*/


/*======course Details start========*/
.main-playicon{
position:absolute;
justify-content:center;
top:200px;
left:330px;
color:#ffacc5
}

.bullet-points{
    color:#693668;
    font-weight:bold;
    font-size:16px;
    text-align:start;
    margin-left:-40px
}
.bullet-points-list{
  list-style-type:none
}

.course-description-details{
    font-size:15px;
    font-weight:500px;
    color:#666
}

.detail-title{
text-align:center;
font-weight:600px;
font-size:25px;
color:#495057
}

.br-10{
    border-radius: 10px;
}
.text-white{
    color: #fff;
}

.fr{
    float: right;
}
.fl{
    float: left;
}
.course-details-img{
     width:100px;
     height:60px;
     object-fit:contain;
     margin-top:-20px;
}

.description{
    margin-top:-10px;
    color:#023e8a
}

.detailed-bg-card{
    background-color:#33415c;
    border-radius:0px;
    border: none;
}
.sub-text{
    margin-top:-10px;
    color:#023e8a
}

.breadcrumb-titles{
    font-size: 14px;
    color: #4361ee;

}
.hover-color:hover{
    background-color: #c6c4c4;  
    opacity: 0.6;
    position: relative;
    margin-left: auto;
    margin-right: auto;
 }
 .lessons-hover{
    padding-top: 30px;
    padding-bottom: 15px;
    text-align: start;
 }
 .sidebar-playicon{
    position:absolute;
    justify-content:center;
    top:30px;
    left:60px;
    color:#ffacc5;
    z-index: 999;
 }
 .list-description{
    color:#495057;
    font-size:18px;
    font-weight: 400px;
}
/*======course Details end========*/


/*======product card start========*/
.product-description{
    color:#495057;
    font-size:13px;
    font-weight: 400px;
}

.sort-title{
    background-color:#fff;
    padding:6px;
    padding-right:80px;
    border:1px solid #d3d3d3
}

.product-card{
border-radius:8px;
width:300px;
height:290px
}
.product-image{
    border-radius: 5px 5px 0px  0px ;
}

.product-title{
    font-size:16px;
    font-weight:bold;
    color:#495057
}
.description-bg{
    background-color:#f3fdfd;
    padding:10px;
    border-radius:30px
}

/*======product card end========*/


/*=====categeory details start========*/

.categeory-title{
    font-size:22px;
    font-weight:500px;
    color:#495057;
    margin-left:15px
}

.categeory-label{
font-size:20px;
color:#495057
}

.categeory-remove-text{
    font-size:15px;
    font-weight:400px;
    color:#cc1100;
    text-align:end
}

/*=====categeory details end========*/

/*=======upload page start======*/
.top-subtext{
    color:#4361ee
}
.text-center{
    text-align: center;
    justify-content: center;
}
.btn-move{
    margin-left: auto;
    margin-right: auto;
}

.upload-title{
    color:#495057;
    margin-left:20px
}

.btn-add-files{
    background-color: #fff;
    border-color: #4361ee;
    padding: 6px 10px;
    width: 100px;
    height: 38px;
}
.btn-add-files:hover{
    background-color: #4361ee;
    color: #fff;
}
.upload-playicon{
    position:absolute;
    justify-content:center;
    top:130px;
    left:150px;
    color:#ffacc5;
    z-index:1
}
.text-vedio{
    position: relative;
    top: -30px;
    left: 60px;
}

/*=======upload page end======*/