.number-card{
    // background-color: var(--green600);
    color: var(--black);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 1.25rem;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    .number{
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 0.875rem;
        color: var(--primary) !important;
    }
    .title{
        opacity: 0.5;
        margin-bottom: 0;
        line-height: 1.25rem;
        color: var(--black);
    }
  }
  
  @media screen and (max-width: 767px) {
    .number-card{
        margin-bottom: 1rem;
        padding: 1rem;
        height: auto;
    }
  }
  
  .blue-bg {background-color: #8081ff; color:#fff;}
  /*--------------------------------------------------------------
  # Dashboard
  --------------------------------------------------------------*/
  /* Filter dropdown */
  .dashboard .filter {
      position: absolute;
      right: 0px;
      top: 15px;
    }
    
    .dashboard .filter .icon {
      // color: #fff;
      padding-right: 20px;
      padding-bottom: 5px;
      transition: 0.3s;
      font-size: 16px;
    }
    
    .dashboard .filter .icon:hover,
    .dashboard .filter .icon:focus {
      color: #333 ;
    }
    
    .dashboard .filter .dropdown-header {
      padding: 8px 15px;
    }
    
    .dashboard .filter .dropdown-header h6 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #aab7cf;
      margin-bottom: 0;
      padding: 0;
    }
    
    .dashboard .filter .dropdown-item {
      padding: 8px 15px;
    }
    
  
  /* Info Cards */
  .dashboard .info-card {
      padding-bottom: 10px;
    }
    
    .dashboard .info-card h6 {
      font-size: 28px;
      color: #ffffff;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
    
    .dashboard .card-icon {
      font-size: 32px;
      line-height: 0;
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      flex-grow: 0;
      // position: absolute;
      top: 30px;
      left: 30px;
    }
    
    .dashboard  .card-icon {
      color: #8081ff ;
      background: #f6f6fe ;
    }
    
   
    
    
    /* Activity */
    .dashboard .activity {
      font-size: 14px;
    }
    
    .dashboard .activity .activity-item .activite-label {
      color: #888;
      position: relative;
      flex-shrink: 0;
      flex-grow: 0;
      min-width: 64px;
    }
    
    .dashboard .activity .activity-item .activite-label::before {
      content: "";
      position: absolute;
      right: -11px;
      width: 4px;
      top: 0;
      bottom: 0;
      background-color: #eceefe;
    }
    
    .dashboard .activity .activity-item .activity-badge {
      margin-top: 3px;
      z-index: 1;
      font-size: 11px;
      line-height: 0;
      border-radius: 50%;
      flex-shrink: 0;
      border: 3px solid #fff;
      flex-grow: 0;
    }
    
    .dashboard .activity .activity-item .activity-content {
      padding-left: 10px;
      padding-bottom: 20px;
    }
    
    .dashboard .activity .activity-item:first-child .activite-label::before {
      top: 5px;
    }
    
    .dashboard .activity .activity-item:last-child .activity-content {
      padding-bottom: 0;
    }
  /* Recent Sales */
  .dashboard .recent-sales {
      font-size: 14px;
    }
    
    .dashboard .recent-sales .table thead {
      background: #f6f6fe;
    }
    
    .dashboard .recent-sales .table thead th {
      border: 0;
    }
    
    .dashboard .recent-sales .dataTable-top {
      padding: 0 0 10px 0;
    }
    
    .dashboard .recent-sales .dataTable-bottom {
      padding: 10px 0 0 0;
    }
    
    /* Top Selling */
    .dashboard .top-selling {
      font-size: 14px;
    }
    
    .dashboard .top-selling .table thead {
      background: #f6f6fe;
    }
    
    .dashboard .top-selling .table thead th {
      border: 0;
    }
    
    .dashboard .top-selling .table tbody td {
      vertical-align: middle;
    }
    
    .dashboard .top-selling img {
      border-radius: 5px;
      max-width: 60px;
    }
      