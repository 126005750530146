footer{
    &.footer-learner{
        .footer-main{
            border-top: 1px solid rgba(#ffffff, $alpha: 0.1);
            display: flex;
            // align-items: center;
            justify-content: right;
            padding-right:10px;
            // color: var(--whitehalf);
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-top: 1rem;
            background: #FFC107;
        
            .termlist{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .nav-item{
                    margin-right: 0.875rem;
                    margin-bottom: 0;
                    .nav-link{
                        text-decoration: none;
                        color: var(--whitehalf);
                    }
                }
            }
            .copyrights{
                margin: 0;
                text-align: right;
                // justify-content: center;
                color: var(--black);
                font-size: 12px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    footer{
        &.footer-learner{
            .footer-main{
                flex-direction: column;
            }
        }
    }
}