.nav-side-wrp{
    display: flex;
    flex-direction:row;
    width: 100%;
    text-align: left;
    padding: 10px;
    background-color:transparent;
    opacity: 1;
    .nav-title{
        font-weight: 600;
        margin-bottom: 1rem;
        color: #fff;
    }
    .nav{
        list-style: none;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        
        .nav-item{
            position: relative;
            left: 0;
            top: 0;
            
            padding-left: 0.5rem;
            padding-bottom: 0.5rem;
            
            &:last-child{
                padding-bottom: 0;
                .nav-link{
                    &::before{
                        content: none;
                    }
                }
            }
            .nav-link{
                text-decoration: none;
                color: var(--white);
                font-weight: 500;
                font-size: 1.75rem;
                &:hover{
                    color: var(--primary);
                }
                &::before{
                    content: '';
                    position: absolute;
                    left: 0.9375rem;
                    background-color: var(--white);
                    width: 1px;
                    height: 100%;
                    top: 0.375rem;
                }
                &::after{
                    content: '';
                    width: 1rem;
                    height: 1rem;
                    border: 2px solid var(--white);
                    background-color: var(--primary);
                    position: absolute;
                    left: 0;
                    top: 0.375rem;
                    border-radius: 50%;
                }
                &.active{
                    color: var(--primary);
                    &::after{
                        border-color: var(--primary);
                        background-color: var(--primary);
                        box-shadow: 0 0 0px 10px inset var(--white);
                    }
                }
                &.marked{
                    color: var(--white);
                    &::after{
                        border-color: var(--primary);
                        background-color: var(--primary);
                        box-shadow: none;
                        background-image: url('../../assets/img/logo.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &::before{
                        background-color: var(--primary);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .nav-side-wrp{
        .nav-title{
            margin-bottom: 3.125rem;
            text-align: center;
        }
        .nav{
            .nav-item{
                padding-bottom: 1.875rem;
                padding-left: 2.75rem;
                .nav-link{
                    font-size: 1.5rem;
                    &::before{
                        left: 0.75rem;
                        top: 0.625rem;
                    }
                    &::after{
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }
    }
}