.magazine-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-align: center;
    margin-bottom: 3.75rem;
    .image-element{
        margin-bottom: 1.25rem;
        img{
            width: 100%;
        }
    }
    .magazine-date{
        margin: 0;
        color: var(--whitehalf);
    }
    .magazine-title{
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--white);
        margin-bottom: 1.25rem;
    }
}
@media screen and (max-width: 767px) {
    .magazine-card{
        margin-bottom: 1.875rem;
        .magazine-date{
            font-size: 0.875rem;
        }
        .magazine-title{
            font-size: 1rem;
        }
        .btn{
            padding: 5px 0.625rem;
            font-size: 1rem;
        }
    }
}