.share-via{
    display: flex;
    flex-direction: column;
    width: calc(100% + 9.375rem);
    border-top: 1px solid rgba(215, 215, 215, 0.2);
    padding-top: 2.5rem;
    margin-left: -4.6875rem;
    margin-right: -4.6875rem;
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
    margin-bottom: -2.1875rem;
    h4{
        color: var(--light);
        opacity: 0.5;
        font-weight: 600;
    }
}
.share-social-more{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    .sm-item-box{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 20%;
        // margin-top: 1.25rem;
        // margin-bottom: 1.25rem;
        padding-left: 5px;
        padding-right: 5px;
        .sm-item {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // color: #ffffff;
             font-size: 14px;
        }
        .btn{
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.125rem;
            font-weight: 400;
            text-transform: inherit;
            .btn-icon-left{
                margin-right: 0;
                margin-bottom: 1.25rem;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .share-social-more{
        .sm-item-box{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 20%;
            margin-top: 0px;
            margin-bottom: 1.25rem;
            .sm-item {
                max-width: 100%;
                font-size: 0.875rem;
            }
        }
    }
}