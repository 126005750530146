.member-tree-search{
    background-color: var(--bright);
    padding: 1.875rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    .mts-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--secondary);
        padding: 0.5rem;
        height: 100%;
        border-radius: 3px;
        line-height: 1.625rem;
        p{
            margin: 0 0 3px;
        }
        .mts-title{
            font-size: 1.5rem;
            line-height: 1.625rem;
            margin-bottom: 0;
        }
    }
    margin-bottom: 4.375rem;
}
.member-tree-search-form{
    display: flex;
    width: 100%;
    flex-direction: column;
    .formBox{
        display: flex;
        flex-direction: column;
        .form-field{
            margin-bottom: 0;
            .form-control{
                height: 3rem;
                padding: 0.5rem 1.5rem;
            }
        }
        .btn{
            height: 3rem;
            width: 100%;
            text-align: center;
            justify-content: center;
        }
    }
}
@media screen and (max-width: 767px) {
    .member-tree-search{
        padding: 0.625rem;
        margin-bottom: 1.25rem;
        .mts-box{
            height: auto;
            margin-bottom: 0.625rem;
            p{
                font-size: 0.75rem;
            }
        }
    }
    .member-tree-search-form{
        .formBox{
            .form-field{
                margin-bottom: 0.625rem;
            }
        }
    }
}

