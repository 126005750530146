.table-responsive{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    table{
        width: 100%;
        font-size: 0.9375rem;
        border: none;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 1.25rem;
        color: var(--light);
        thead {
            th{
                font-weight: 500;
                text-align: left;
                padding: 0.5rem;
                border: 0;
                vertical-align:center ;
                font-size: 1.00rem;
                color: var(--white);
                font-weight: 500;
                margin-left: auto;
                margin-right: auto;
                background-color: var(--primary);
            }
        }
        tbody{
            tr{
                td{
                    border-width: 1px 0 0 0;
                    border-style: solid;
                    text-align: left;
                    border-color: rgba(124, 124, 124, 0.5);
                    padding: 0.9375rem;
                    color: var(--gray300);
                    padding-left: 5px;
                }
            }
        }

       
    }
}

.ml-10px{
    margin-left: 5px !important;
}

.linkbtnsize{
    font-weight: 120 !important;
    text-align: center !important;
    border: 0;
    font-size: 1.00rem;
    color: var(--black);
    font-weight: 100;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--primary);
}

.ant-table {
  width: 100%;
        font-size: 0.9375rem;
        border: none;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 1.25rem;
        color: var(--primary);
        thead {
            th{
                font-weight: 500;
                text-align: left;
                padding: 0.5rem;
                border: 0;
                vertical-align:center ;
                font-size: 1.00rem;
                color: var(--black);
                font-weight: 500;
                margin-left: auto;
                margin-right: auto;
                background-color: var(--primary);
            }
        }
        tbody{
            tr{
                td{
                    border-width: 1px 0 0 0;
                    border-style: solid;
                    text-align: left;
                    border-color: rgba(124, 124, 124, 0.5);
                    padding: 0.9375rem;
                    color: var(--gray300);
                }
            }
        }
 }

 .ant-table-thead .ant-table-cell {
    background-color: var(--primary) !important;
    color: #fff !important;
    border: 0 !important;
    border-collapse: collapse !important;
  }

  .ant-table-tbody > tr > td > th{
    border: none !important;
    
  }

  .ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: rgba(218, 218, 218, 0.5) !important;
  }
  
  .ant-table-thead > tr > th {
    border-color: var(--primary) !important;
  }

  @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    
	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}

    
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

    td:nth-of-type(1):before { content: "Date"; }
    td:nth-of-type(2):before { content: "User Name"; }
    td:nth-of-type(3):before { content: "Course Title"; }
    td:nth-of-type(4):before { content: "Module Title"; }
    td:nth-of-type(5):before { content: "Video Title"; }


    .table-responsive{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: auto;
        table{
            width: 100%;
            font-size: 0.9375rem;
            border: none;
            border-collapse: collapse;
            border-spacing: 0;
            margin-bottom: 1.25rem;
            color: var(--light);
            thead {
                th{
                    font-weight: 500;
                    text-align: left;
                    padding: 0.5rem;
                    border: 0;
                    vertical-align:center ;
                    font-size: 1.00rem;
                    color: var(--black);
                    font-weight: 500;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: var(--primary);
                }
            }
            tbody{
                tr{
                    td{
                        border-width: 1px 0 0 0;
                        border-style: solid;
                        text-align: left;
                        border-color: rgba(124, 124, 124, 0.5);
                        padding: 0.9375rem;
                        color: var(--gray300);
                        padding-top:30px;
                        padding-left:5px;
                    }
                }
            }
    
           
        }
    }

}