/*==========pricing section css==========*/
.pricing-table {
    display: flex;
    flex-wrap: wrap;
  }
  .pricing-table .plan {
    background: #fff;
    border-radius: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .pricing-table .plan .plan-header {
    background: #8081ff;
    padding: .95rem 1rem;
  }
  .pricing-table .plan .plan-header h3 {
    color: #fff;
    
    font-size: .8rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .pricing-table .plan .plan-price {
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    border-top: 0;
    border-bottom: 0;
    padding: 2rem .8rem;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
  }
  .pricing-table .plan .plan-price, .pricing-table .plan .plan-features, .pricing-table .plan .plan-footer {
    /* border: 1px solid #ededed; */
  }
  .pricing-table .plan .plan-price .price {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 1;
    color: var(--homeCardWhite);
  }
  .price {
    min-height: 28px;
  }
  .pricing-table .plan .plan-price .price .price-unit {
    font-size: 50%;
    line-height: 1.4;
  }
  .pricing-table .plan .plan-price .price-label {
    text-transform: uppercase;
    color: #777;
    font-size: .7rem;
    line-height: 1;
    margin-bottom: 0;
  }
  label {
    display: inline-block;
  }
  .pricing-table .plan .plan-features {
    border-top: 0;
    border-bottom: 0;
    padding: .8rem;
    text-align: center;
  }
  .pricing-table .plan .plan-features ul {
    padding: 0 .5rem;
    margin-bottom: 0;
  }
  .pricing-table .plan .plan-features ul li {
    border-bottom: 1px solid #ededed;
    line-height: 20px;
    list-style: none;
    font-size: .9em;
    display: inline-block;
    text-align: start;
    padding: 5px 0px;
  }
  .pricing-table .plan .plan-features {
    text-align: left;
  }
  .pricing-table .plan .plan-footer {
    border-top: 0;
    padding: 1.5rem .8rem 2.5rem;
    text-align: center;
  }
  html .btn-outline.btn-dark {
    color: #212529;
    background-color: transparent;
    background-image: none;
    border-color: #212529;
  }
  html .btn-dark {
    --color: #212529;
    --hover: #32383e;
    --disabled: #212529;
    --active: #101214;
  }
  .btn-modern {
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 600;
  }
  .btn-outline {
    border-width: 2px;
  }
  html .btn-outline.btn-dark:hover,
  html .btn-outline.btn-dark.hover {
    color: #FFF;
    background-color: #212529;
    border-color: #212529;
  }
  html .btn-outline.btn-dark:focus,
  html .btn-outline.btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
  }
  
  .pricing-table .plan.plan-featured {
    position: relative;
    box-shadow: 0 0 40px rgba(200,200,200,.3);
    transform: scale(1.15);
    z-index: 1;
  }
  .pricing-table .plan {
    background: #fff;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #c0c0c0;
  }
  .align-top{
    margin-top: 20px !important;
  }
  
  