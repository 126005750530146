.video-details-main-wrapper{
    display: flex;
    width: 100%;
    position: relative;
    .video-details-main-side{
        width: 0px;
       /* position: absolute;*/
        left: 0;
        z-index: 1;
        height: 100%;
        &.video-details-main-side-active{
            width: 100%;
            left: 0;
            .side-panel-lists-wrapper{
                .side-panel-lists-heading{
                    white-space: nowrap;
                    margin-left: 100%;
                }
            }
        }
    }
    /*.video-details-main-right{
        width: 100%;
        &.video-details-main-right-active{
            width: calc(100% - 320px);
            margin-left: 320px;
        }
    }*/
    @media screen and (max-width: 648px) {
        .col-12.text-center.dashboard-share-icon {
          padding-left: 18%;
          display: inline;
        }
      }
      .dashboard-share-icon {
        height: 20px;
        width: 20px;
        margin-bottom: 10px;
        margin-left: 5px;
        display: inline !important;
      }
    .vpd-watch-video{
        position: relative;
        .vpd-watch-video-replay{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: rgba(8, 29, 25, 0.83);
            padding: 2rem 1rem;
            .vpd-watch-video-replay-icon{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .vpd-watch-next{
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 6rem;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .video-details-main-wrapper{
        .video-details-main-side{
            /*width: 95%;*/
            z-index: 2;
            &.video-details-main-side-active{
                left: 0;
                .side-panel-lists-wrapper{
                    .side-panel-lists-heading{
                        margin-left: 0;
                    }
                }
            }
        }
        .video-details-main-right{
            width: 100%;
            &.video-details-main-right-active{
                width: 100%;
                margin-left: 0;
            }
        }
        .vpd-watch-video{
            .vpd-watch-video-replay{
                padding: 1rem;
                .vpd-watch-next{
                    margin-top: 1rem;
                }
            }
        }
    }
}

.video-details-main-side.video-details-main-side-active{
    height: 460px;
    overflow: scroll;
}