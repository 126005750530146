
  .item {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    background: white;
  }
  
  .card-body{
    width: 100% !important;
  }

  .accordion-button{
    width: 100% !important;
    padding:0px !important;
    margin:0px !important;
  }

  .accordion-collapse{
    width: 100% !important;
    margin-top: 0px;
    background-color: transparent !important;
  }

  .category-container {
    /*width: 90%;*/
    background-color:transparent !important;
  }

  .accordion-header{
    margin-top:0px;
    
    padding:10px;
  }


  .accordion-odd-color{
    background: var(--gray100) !important;
    background-color:var(--primary500) !important;
    
  }

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  width: 100% !important;
}
.accordion-even-color {
  background: var(--light700) !important;
  background-color: var(--white) !important;
  width: 100% !important;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #29008a;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.child{
  background: var(--gray900) !important;
  border: 0 !important;
  box-shadow: none;
  
    border-color: rgba(0,0,0,.125);
}

