.video-details-main{
    .video-details-card{
        width: 100%;
        margin-bottom: 1.875rem;
        .video-details-content{
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            color: var(--whitehalf);
            font-size: 0.75rem;
            h4{
                font-weight: 600;
                line-height: 1.375rem;
            }
            > p{
                margin: 0;
            }
        }
    }
}
.thumbnail-wrapper{
    display: flex;
    flex-direction: column;
    .thumbnail-upload-file-wrp{
        width: 208px !important;
        /*background-color: var(--light800half);*/
        background-image: url('../../../assets/img/uploadImage.png');
        // background-repeat: no-repeat;
        background-size: auto;
        color: var(--light800half);
        border: 1px dashed #8081ff;
        border-radius: 5px;
        cursor: pointer;
        .dropzone{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 8.375rem;
            cursor: pointer;
        }
    }
    .thumbnail-upload-wrap{
        background-color: var(--secondary500);
        color: var(--light800half);
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 5px;
        width: 100%;
        height: 8.375rem;
        position: relative;
        cursor: pointer;
        .thumbnail-select-wrap{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            padding: 0.625rem;
            border: 2px solid var(--primary);
            border-radius: 5px;
        }
        img{
            border-radius: 5px;
            max-width: 100%;
        }
        &.thumbnail-upload-added{
            background-color: transparent;
            height: auto;
        }
    }
    
}
.video-preview-details-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.875rem 1.25rem;
    background-color: var(--bright);
    border-radius: 5px 5px 0 0;
    .video-details-info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1.25rem;
        .avatar{
            margin-right: 0.75rem;
        }
        .video-details-info-content{
            width: calc(100% - 3.25rem);
            color: var(--whitehalf);
            .video-details-info-content-title{
                font-size: 1.125rem;
                line-height: 1.2;
                margin-bottom: 0.625rem;
                color: var(--white);
            }
            .video-details-info-content-by{
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 0;
            }
            .video-details-info-content-short-desc{
                font-size: 0.75rem;
                line-height: 1.25rem;
                margin-bottom: 0;
            }
        }
    }
}
.action-buttons-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 1rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    .btn + .btn{
        margin-left: 1.25rem;
    }
}
.action-buttons-center{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 1rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    .btn + .btn{
        margin-left: 1.25rem;
    }
} 
.thumbimgempty{
    background-color: var(--secondary500);
    border-radius: 5px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.top-text-video{
    height: 80px;
}