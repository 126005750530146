.showing-pagination-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 2.5rem;
}
.pagination{
    display: flex;
    align-items: center;
    color: var(--gray800);
    .pagination-prev{
        margin-right: 1.5rem;
        width: 1.75rem;
        height: 1.875rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--gray800);
        cursor: pointer;
    }
    .pagination-next{
        margin-left: 1.5rem;
        width: 1.75rem;
        height: 1.875rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--gray800);
        cursor: pointer;
    }
}
