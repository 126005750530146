/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
    padding: 30px;
    background: #8081ff;
    border-radius: 4px;
    color: #fff;
  }
  
  .why-us .content h3 {
    font-weight: 700;
    font-size: 34px;

  }
  
  .why-us .content p {
    margin-bottom: 30px;
  }
  
  .why-us .content .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
    text-decoration: none;
  }
  
  .why-us .content .more-btn i {
    font-size: 14px;
  }
  
  .why-us .content .more-btn:hover {
    color: #8081ff;
    background: #fff;
  }
  
  .why-us .icon-boxes .icon-box {
    /* text-align: center; */
    background: #fff;
    padding: 10px 10px;
    width: 100%;
    /* border: 1px solid #eef0ef; */
    border-radius: 4px;
  }
  
  .icon-box .choose-us{
    font-size: 32px;
    padding: 10px;
    color: #8081ff;
    margin-bottom: 10px;
    background: #ecf9f0;
    border-radius: 50px;
    width: 50px;
    height: 50px;
  }
  
  .why-us .icon-boxes .icon-box h4 {
    /* font-size: 20px; */
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  
  .why-us .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
  }

  