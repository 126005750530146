.tis-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: hidden;
    .image-element{
        img{
            width: 100%;
        }
    }
    .tis-content{
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .icon{
            margin-bottom: 5px;
        }
        .title{
            font-size: 1.25rem;
            margin-bottom: 0;
        }
        .tis-list-item{
            display: flex;
            align-items: center;
            margin-top: 0.625rem;
            .tis-list-item-icon{
                display: flex;
                flex-direction: column;
                width: 1rem;
                margin-right: 0.625rem;
            }
            .tis-list-item-text{
                display: flex;
                width: calc(100% - 2.125rem);
            }
        }
    }
    .image-element img{
        border-radius: 5px;
    }
}