.reference-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--bright);
    padding: 5.625rem 4.6875rem;
    color: var(--white);
    position: relative;
    &::before{
        content: '';
        /*background-image: url('../../../assets/images/bg-circle-small.svg');*/
        background-position: right top;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 8.25rem;
        height: 7.375rem;
        z-index: 0;
    }
    &::after{
        content: '';
        /*background-image: url('../../../assets/images/bg-circle-big.svg');*/
        background-position: right top;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .reference-main{
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}