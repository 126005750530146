.progress-bar-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8125rem;
    color: var(--whitehalf);
    .progress-bar-text{
        margin: 0 0 0.625rem !important;
        // &.text-danger{
        //     color: var(--danger);
        // }
    }
    .progress-bar-main{
        display: flex;
        height: 5px;
        overflow: hidden;
        background-color: #DDE2E1;
        border-radius: 0.625rem;
        .progress-bar{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            color: var(--white);
            text-align: center;
            white-space: nowrap;
            background-color: var(--primary);
            transition: width 0.6s ease;
        }
    }
}
.progress-bar-secondary-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8125rem;
    color: var(--whitehalf);
    margin-bottom: 1.5rem;
    .progress-bar-text{
        margin: 0 0 0.625rem !important;
        &.text-right{
            text-align: right;
            color: var(--white);
        }
    }
    .progress-bar-main{
        display: flex;
        height: 0.9375rem;
        overflow: hidden;
        background-color: var(--white);
        border-radius: 5px;
        .progress-bar{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            color: var(--white);
            text-align: center;
            white-space: nowrap;
            background-color: var(--white);
            transition: width 0.6s ease;
            border-radius: 5px;
        }
    }
    &.progress-bar-small{
        .progress-bar-main{
            height: 0.625rem;
        }
    }
}