
.monthSlotBlocked {
    font-family: var(--defaultfontfamily);
    padding: 0.625rem 1rem;
    border-radius: 5px;
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    border: 1px transparent;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-color: '#5cc85c';
    border-width: 1;
    align-items: 'center';
    padding:10px;
    margin: 10px;
    color: '#cdc';
    background-color: var(--blackhalf);
  }


.monthSlotInactive {
    font-family: var(--defaultfontfamily);
    padding: 0.625rem 1rem;
    border-radius: 5px;
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    border: 1px transparent;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-color: '#5cc85c';
    border-width: 1;
    align-items: 'center';
    padding:10px;
    margin: 10px;
    color: '#cdc';
    background-color: var(--primary500);
  }

.monthSlotActive{
    font-family: var(--defaultfontfamily);
    padding: 0.625rem 1rem;
    border-radius: 5px;
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    border: 1px transparent;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-color: '#5cc85c';
    border-width: 1;
    align-items: 'center';
    padding:10px;
    margin: 10px;
    color: '#fff' !important;
    background-color: var(--primary);
}

.activeText{
    color:'#fff' !important;
}