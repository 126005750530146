/*--------------------------------------------------------------
# Courses
--------------------------------------------------------------*/
.courses .course-item {
  border-radius: 5px;
  border: 1px solid #eef0ef;
  height: 100%;
}

.courses .course-content {
  padding: 15px;
}

.courses .course-content h3 {
  font-weight: 700;
  font-size: 20px;
}

.courses .course-content h3 a {
  color: #37423b;
  transition: 0.3s;
  text-decoration: none;
}

.courses .course-content h3 a:hover {
  color: #8081ff;
}

.courses .course-content p {
  font-size: 14px;
  color: #777777;
}

.courses .course-content h4 {
  font-size: 14px;
  background: #8081ff;
  padding: 7px 14px;
  color: #fff;
  margin: 0;
}

.courses .course-content .price {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: #37423b;
}

.courses .trainer {
  padding-top: 15px;
  border-top: 1px solid #eef0ef;
}

.courses .trainer .trainer-profile img {
  max-width: 30px;
  height: 30px;
  border-radius: 50px;
}

.courses .trainer .trainer-profile span {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #5a6c60;
}

.courses .trainer .trainer-rank {
  font-size: 18px;
  color: #657a6d;
}

.all-scroll    { cursor: all-scroll; }

 .label-text-img {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 !important;
  border-radius: 14px !important;
}

.cursors > div {
  float: left;
  box-sizing: border-box;
  background: #f2f2f2;
  border:1px solid #ccc;
  width: 20%;
  padding: 10px 2px;
  text-align: center;
  white-space: nowrap;
  &:nth-child(even) {
     background: #eee;
  }
  &:hover {
     opacity: 0.25
  }
}

.bg-col-white{
  background-color: var(--homeCardWhite);
}
.card-img-height {
  min-height: 180px;
}
