.plan-summary-card{
    background-color: var(--green300);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5625rem;
    margin-bottom: 3.125rem;
    .plan-summary-title{
        h5{
            margin-bottom: 1rem;
            font-weight: 500;
            font-size: 1.125rem;
            color: var(--black600);
        }
    }
    .plan-summary-body{
        p{
            margin-bottom: 0;
            font-weight: 600;
            font-size: 1.125rem;
            color: var(--black);
        }
    }
}
@media screen and (max-width: 767px) {
    .plan-summary-card{
        padding: 1rem;
        margin-bottom: 1.875rem;
        .plan-summary-title{
            h5{
                font-size: 1rem;
                &.plan-summary-title-left{
                    width: 60%;
                }
            }
        }
        .plan-summary-body{
            p{
                margin-bottom: 0;
                font-weight: 600;
                font-size: 1rem;
                color: var(--black);
                &.plan-summary-body-left{
                    width: 60%;
                }
            }
        }
    }
}