/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
html,body{
  overflow-x: hidden;
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8081ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8081ff !important;
}

section {
    padding: 60px 0;
    /* overflow: hidden; */
  }
  
  .section-bg {
    background-color: #f6f7f6;
  }
  
  .section-title {
    padding-bottom: 2%;
  }
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color:var(--primary);
    font-family: "Poppins", sans-serif;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #8081ff;
    margin: 4px 10px;
  }
  
  .section-title p {
    margin: 0;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #37423b;
  }
  
  .breadcrumbs {
    margin-top: 73px;
    text-align: center;
    background: #8081ff;
    padding: 30px 0;
    color: #fff;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 63px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 32px;
    font-weight: 500;
  }
  
  .breadcrumbs p {
    font-size: 14px;
    margin-bottom: 0;
  }
  .text-5{
    margin-top: 10px;
  }


  /*=====login css====*/
   
 .login{  
  background-color:#8081ff; 
  } 
  .register{  
    background-color:#8081ff; 
  } 
  .branding-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:60px;
    left: 20px;
    background-position: left center;
    width: 60%;
  }

  .branding-logo-register-view{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    position: fixed;
    top:60px;
    left: 30%; 
    background-position: left center;
    width: 60%;
  }

  @media (max-width: 640px) {
    .branding-logo-register-view  img{
      width: 200px; 
    }
  }

 

.register_flow{
  /* max-width: 380px; */
  /* float:right; */
  overflow-y: scroll;
  height: 90vh;
}

  .card-title {
    padding: 5px 0 15px 0;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
  }

  
  .btn-save{
    margin-top: -20px;
  }

  .autherization_card{
    width: 380px;
    height: 100%;
  }

  .purchase_card{
    border-radius: 8px;
    height: 125px;
    width: 100%;
  }
  .purchase_card_payment{
    border-radius: 8px;
    height: 115px;
    width: 100%;
  }
  .purchase_card_sub{
    border-radius: 8px;
    /* height: 115px; */
    width: 100%;
  }
  .purchase_card_1{
    border-radius: 8px;
    margin-top: 30px;
    height: 150px;
    width: 100%;
  
  }
  .count_card{
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    /* height: 115px; */
    background-color:#8889bc;
  }

  .btn-donate{
    background-color: #FFD109;
    padding: 6px 15px;
    border: 1px solid #FFD109;
    color: var(--white);
    border-radius: 8px;
    margin-top: 5px;
  }
  .btn-donate_1{
    background-color:var(--white);
    padding: 4px 15px;
    border: 1px solid var(--white);
    color: var(--primary);
    border-radius: 5px;
    margin-right: 10px;
  }

  .purchase{
    background-image: url(../img/about.jpg);
background-repeat: no-repeat;
background-size: cover;
width: 100%;
height: 100px;
border-radius:8px;
  }
  .purchase_1{
    background-image: url(../img/about.jpg);
background-repeat: no-repeat;
background-size: cover;
width: 100%;
height: 100px;
border-radius:8px
  }
  .purchase_2{
    background-image: url(../img/about.jpg);
background-repeat: no-repeat;
background-size: cover;
width: 100%;
height: 100px;
border-radius:8px;
  }

  .box-shadow-4:not(.box-shadow-hover) {
    box-shadow: 0 0 21px -5px rgba(0, 0, 0, .2)
}

.box-shadow-4.box-shadow-hover:hover {
    box-shadow: 0 0 21px -5px rgba(0, 0, 0, .2)
}
.text-4{
  font-size:17px !important;
}

.text-lightdark{
  color: var(--gray300);
}

.btn-align{
  border-radius: 8px;
  padding:6px 8px;
}
.btn-outline{
  background-color: transparent;
}
.btn-success{
  border-color: #4DC14B;
  color: #4DC14B;
}

.btn-purchase{
  background-color: #FFD109;
  border: 1px solid #FFD109;
  color: #fff;
  padding: 6px 18px;
  border-radius: 8px;
}
.btn-purchase_1{
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
  padding: 12px 20px;
  border-radius: 8px;
}


.btn-paymentsuccess{
  background-color:#4DC14B;
  border: 1px solid #4DC14B;
  color: #fff;
  padding: 6px 20px;
  border-radius: 8px;
}
.btn-failure{
  background-color: red;
  border: 1px solid red;
  color: #fff;
  padding: 6px 20px;
  border-radius: 8px;
}

.survey_card{
  background-color: var(--white);
  padding: 25px;
  height: 380px;
}

.left_block{
  background-color:#01BFBD;
  width: 100%;
  max-height: 100%;
}
.layout_image{
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  height: 400px;
  position: relative;
  top: 25%;
  display: block;
  border-radius: 6px;
}
.register_instructor{
  background-color: #8081ff;
}


.learner_video{
background-size: cover;
background-color: transparent;
background-repeat: no-repeat;
background-position: 50% 50%; 
background-image: none;
}
.learner_video_demo{
  margin: auto;
  opacity: 1;
  visibility: visible;
  width: 100%; 
  height: auto;
}
.overlay {
  position: relative
}

.overlay:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: #212529;
  opacity: 0;
  pointer-events: none;
  transition: ease opacity 300ms 100ms
}

.overlay:hover:before,
.overlay.overlay-show:before,
.overlay.show:before {
  opacity: .8
}

.overlay.overlay-op-1:hover:before,
.overlay.overlay-op-1.overlay-show:before,
.overlay.overlay-op-1.show:before {
    opacity: .1
}

.point_block{
  position: relative;
  background-color: #f7f7f7;
  color: #000;
  justify-content: center;
  text-align: justify;
  top: 0px;
  padding: 25px 0px;
}

.btn-subscribe{
  background-color: #8081ff;
  border: 1px solid #8081ff;
  color: #fff;
  padding: 9px 25px;
  border-radius: 8px;
}
.W-50{
  width: 100%;
}


.process {
  text-align: center
}

.process .process-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative
}

.process .process-step-circle {
  display: flex;
  justify-content: center;
  height: 90px;
  text-align: center;
  width: 90px;
  border: 2px solid #ccc;
  border-radius: 50%;
  align-items: center;
  position: relative;
  background-color: transparent;
  transition: all .2s ease-in-out
}

.process .process-step-content {
  padding-top: 20px
}

.process .process-step-circle-content {
  font-weight: 700;
  color: #212529;
  font-size: 1.6em;
  letter-spacing: -1px;
  transition: all .2s ease-in-out
}

.process .process-step:hover .process-step-circle {
  background-color: #ccc
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
  color: #fff
}

.process .process-step:hover .process-step-circle .process-step-circle-content .icons {
  color: #fff !important
}

.process.process-shapes .process-step-circle {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  margin-bottom: 19.2px;
  margin-bottom: 1.2rem
}

.process.process-shapes .process-step-circle .process-step-circle-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: #ccc;
  border-radius: 100%;
  color: #fff;
  z-index: 3
}

.process.process-shapes .process-step-circle:before,
.process.process-shapes .process-step-circle:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #777;
  border-radius: 100%;
  opacity: .6;
  transform: translate3d(-50%, -50%, 0) scale(1.5);
  transition: ease transform 1s;
  z-index: 2
}

.process.process-shapes .process-step-circle:after {
  background-color: #999;
  opacity: .3;
  transform: translate3d(-50%, -50%, 0) scale(2);
  z-index: 1
}

.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:before {
  animation-name: processShapesScaleOne;
  animation-delay: 300ms
}

.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:after {
  animation-name: processShapesScaleTwo
}

.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:before,
.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:after {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: linear
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:before {
  animation-name: processShapesScaleOne;
  animation-delay: 300ms
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:after {
  animation-name: processShapesScaleTwo
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:before,
.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:after {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: linear
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle.process-shapes-always-animate-delay:before {
  animation-name: processShapesScaleOne;
  animation-delay: 1000ms
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle.process-shapes-always-animate-delay:after {
  animation-name: processShapesScaleTwo;
  animation-delay: 700ms
}
.process .process-step-circle {
  border-color:#08c
 }
 .process .process-step:hover .process-step-circle {
  background-color:#08c
 }
 .process .process-step:hover .process-step-circle .process-step-circle-content {
  color:#fff!important
 }
 .process.process-shapes .process-step-circle .process-step-circle-content,
 .process.process-shapes .process-step-circle:before,
 .process.process-shapes .process-step-circle:after {
  background-color:#08c
 }
 .process-connecting-line .connecting-line {
  background:#08c
 }



 .feature-box {
  display: flex;
}
.feature-box-steps .feature-box-step-number {
  width: 60px;
}
.feature-box-steps .feature-box-step-number em {
  -webkit-text-stroke: 2px #ec4f4f;
}
.feature-box-steps .feature-box-step-number em {
  font-size: 44px;
  font-style: normal;
  display: block;
  margin-top: 32px;
  margin-right: 10px;
  color: #fff;
  font-weight: 700;
  transform: rotate(-15deg);
}
.feature-box .feature-box-icon.feature-box-icon-extra-large {
  width: 4.5em;
  height: 4.5em;
}

.feature-box .feature-box-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  background: rgba(0,0,0,.06);
  border-radius: 100%;
  color: #fff;

  font-size: 1rem;
  position: relative;
  top: 7px;
  text-align: center;
  padding: .8em;
}
html .icon-fill-color-primary {
  fill: #ec4f4f !important;
}
.feature-box .feature-box-info {
  flex: 1 1 100%;
  padding-left: 15px;
}
.text-1 {
  font-size: .8em !important;
}
html .text-color-secondary, html .text-secondary {
  color: #332859 !important;
}

.text-welcome{
  color: #ec4f4f;
}
.text-gray{
  color: #fff;
}
.text-light-dark{
  color: #333;
}
.text-darklight{
  color: var(--gray400);
}

@media only screen and (max-width:767px) {
  .point_block{
    padding: 10px 15px;
  }
  .btn-subscribe{
    margin-top: 15px;
  }
  .feature-box{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
  }
  .login .branding-logo-register-view{
    position: relative;
    left: 25%;
    padding-bottom: 10%;
    display: none;
  }
  .login .branding-logo{
    display: none;
  }
  .feature-box-icon{
    text-align: center;
  }
}

.register-view{
  height: 100vh;

}



.bg-course{
  background-color: #01BFBD;
  padding-top:10px;
}
.t-condition{
  overflow-y: scroll;
  height: 500px;
}
