.video-players-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .video-players-image{
        display: flex;
        flex-direction: column;
        width: 100%;
        .image-element{
            img{
                width: 100%;
            }
        }
        .no-players-available-display{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 257px;
            position: relative;
            .no-players-text{
                position: absolute;
            }
        }
    }
    .video-players-control{
        display: flex;
        flex-direction: column;
        .video-players-control-main{
            background-color: rgba(11, 11, 11, 0.5);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 1.25rem;
            font-size: 0.875rem;
            font-weight: 400;
            .video-players-control-left{
                display: flex;
                align-items: center;
                .control-play{
                    display: flex;
                    margin-right: 1.5625rem;
                    cursor: pointer;
                    align-items: center;
                }
                .control-next{
                    display: flex;
                    margin-right: 1.5625rem;
                    cursor: pointer;
                    align-items: center;
                }
                .control-sound{
                    display: flex;
                    margin-right: 1.5625rem;
                    cursor: pointer;
                    align-items: center;
                }
                .control-duration{
                    display: flex;
                    margin-right: 1.5625rem;
                    cursor: pointer;
                    align-items: center;
                }
            }
            .video-players-control-right{
                display: flex;
                align-items: center;
                .control-expand{
                    display: flex;
                    margin-left: 1.5625rem;
                    cursor: pointer;
                }
            }
        }
    }
}
.next-icon {
    cursor: pointer;
}
