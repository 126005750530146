.form-check{
    display: flex;
    margin-bottom: 1.875rem;
    ~.message-error {
        margin-top: -1.25rem;
        margin-bottom: 1.25rem;
    }
    .form-field{
        width: auto;
        margin: 0 0.625rem 0 0;
        padding: 0;
        .form-control{
            height: auto;
            margin: 0;
            width: 1.5625rem;
            height: 1.5625rem;
            border-radius: 5px;
        }
    }
    .form-check-label{
        font-weight: 400;
        font-size: 1rem;
        color: var(--black400);
    }
}
