ul.list-ul{
    color: var(--black);
    margin: 1.5rem 0 0;
    padding: 0;
    font-size: 1.0625rem;
    line-height: 1.2;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    &.list-ul-column-two{
        flex-direction: row;
        flex-wrap: wrap;
        li.list-item{
            width: 50%;
        }
    }
    li.list-item{
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5rem;
        width: 100%;
        &:last-child{
            margin-bottom: 1.25rem;
        }
        .icon{
            margin-right: 1.0625rem;
        }
        .list-item-text{
            width: calc(100% - 2.375rem);
            display: flex;
            flex-direction: column;
            h4{
                margin-bottom: 0;
            }
            p{
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    ul.list-ul{
        margin: 1.25rem 0 0;
        padding: 0;
        font-size: 1rem;
        &.list-ul-column-two{
            flex-direction: column;
            li.list-item{
                width: 100%;
            }
        }
        li.list-item{
            margin-bottom: 0.9375rem;
            &:last-child{
                margin-bottom: 0.9375rem;
            }
            .icon{
                margin-right: 0.9375rem;
            }
            .list-item-text{
                width: calc(100% - 2.375rem);
            }
        }
    }
}