.share-affiliate-link-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    background-color: #8081ff;
    padding: 1rem;
    border-radius: 5px;    
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin: 1rem 0rem;
   
    .share-via{
        margin: 0;
        padding: 0;
        width: 100%;
        border: 0;
        .flex-class{
            flex-wrap: wrap;
            h4{
                width: 100%;
                text-align: left;
                color: var(--white);
                margin-bottom: 1.25rem !important;
                opacity: 1;
                font-weight: 400;
            }
        }
    }
    .modal-wrapper{
        &.modal-signup-right-area{
            position: fixed;
            .modal-content{
                max-width: 50%;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .share-affiliate-link-wrapper{
        padding: 1.875rem 1.25rem;
        border-radius: 5px;
        .modal-wrapper{
            &.modal-signup-right-area{
                position: fixed;
                .modal-content{
                    max-width: 90%;
                }
            }
        }
    }
}

.share-links{
    position: fixed;
}