.text-readonly-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
    .text-readonly-item-label{
        font-weight: 300;
        font-size: 1rem;
        line-height: 0.9375rem;
        color: var(--black);
        display: flex;
        opacity: 0.5;
        margin-bottom: 0.75rem;
    }
    .text-readonly-item-title{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.625rem;
        display: flex;
        color: var(--black);
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}
@media screen and (max-width: 767px) {
    .text-readonly-item{
        margin-bottom: 1.25rem;
    }    
}