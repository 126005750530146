.navbar-guru{
    display: flex;
    width: 100%;
    flex-direction: column;
    .nav-wrp{
        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    .navbar-nav{
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        .nav-item{
            display: flex;
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            .nav-link{
                text-decoration: none;
                color: var(--black);
                font-weight: 500;
                font-size: 1rem;
                text-transform: uppercase;
                cursor: pointer;
                &:hover{
                    color: var(--inactive);
                }
            }
        }
    }
}
.navbar-guru{
    .nav-side-toggle{
        color: var(--white);
        margin-bottom: 1.5rem;
        justify-content: flex-start;
    }
    &.navbar-side-expand{
        .nav-wrp{
            .nav{
                .nav-item{
                    .nav-link{
                        .nav-link-title{
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    &.navbar-side-minimize{
        .nav-wrp{
            .nav{
                .nav-item{
                    .nav-link{
                        .nav-link-title{
                            white-space: nowrap;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}