.signup-layout-wrapper{
    display: flex;
    width: 100%;
    .signup-layout-left{
        width: 40%;
        padding: 6.25rem 4.6875rem;
        min-height: 100vh;
        background-color: var(--secondary100);
        color: var(--white);
        position: relative;
        &::before{
            content: '';
            background-image: url('../../assets/img/logo.svg');
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: left bottom;
        }
        &::after{
            content: '';
            background-image: url('../../assets/img/logo.svg');
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: right top;
        }
        .signup-layout-left-main{
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            height: 100%;
            z-index: 1;
        }
    }
    .signup-layout-right{
        width: 60%;
        padding: 3.125rem 5rem;
        position: relative;
        .signup-layout-right-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.875rem;
            .btn{
                font-size: 1.125rem;
                font-weight: 500;
                text-transform: inherit;
            }
        }
        .signup-layout-right-content{
            display: flex;
            flex-direction: column;
        }
    }
}
@media screen and (max-width: 767px) {
    .signup-layout-wrapper{
        flex-direction: column;
        .signup-layout-left{
            width: 100%;
            padding: 1.875rem 1.875rem;
            min-height: inherit;
            &::before{
                content: '';
                background-image: url('../../assets/img/logo.svg');
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: left bottom;
            }
            &::after{
                content: '';
                background-image: url('../../assets/img/logo.svg');
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: right top;
            }
            .signup-layout-left-main{
            }
        }
        .signup-layout-right{
            width: 100%;
            padding: 1.25rem;
            .signup-layout-right-top{
                .btn{
                }
            }
            .signup-layout-right-content{
                display: flex;
                flex-direction: column;
            }
        }
    }
}
