
  /* Course Details */
.custom-course-detail .custom-course-detail-avatar {
    max-height: 50px;
    max-width: 50px;
    border-radius: 50% ;
    overflow: hidden;
  }
  
  @media (max-width: 767px) {
    .custom-course-detail .border-right {
        border: 0 ;
    }
  }
  @media (max-width: 767px) {
    .custom-instructor-details .border-right {
        border: 0 ;
        border-radius: 8px;
    }
  }
  .border-right {
    border-right: 1px solid #d3d3d3 ;
  }
  .img-thumbnail {
    border-radius: 4px;
    position: relative
}

.img-thumbnail img {
    border-radius: 8px
}
.img-thumbnail.img-thumbnail-no-borders {
    border: none;
    padding: 0
}  
.text-4 {
    font-size:12px;
 text-decoration: none;
 list-style-type: none;
 line-height: 30px;
 text-align: start;
 color: #666;
margin-top: -40px;
padding: 10px;
}
.text-2 {
  font-size:.9em;
  text-decoration: none;
 }
 
   
   html .text-color-secondary,
html .text-secondary {
  color: #e36159 ;
  text-decoration: none !important;
}
.p-relative{
    position: relative;
}
.text-3-4,
.text-3-5 {
 font-size:12px;
 text-decoration: none;
 list-style-type: none;
 line-height: 30px;
 text-align: start;
 color: #666;
 padding-left: 18px;
margin-top: -35px;
}
.opacity-6 {
  opacity: .6 !important;
}
.text-6 {
    font-size:18px
   }
   .line-height-1 {
    line-height:1
   }
   .font-weight-semibold,
.font-weight-semi-bold {
 font-weight:600
}
.text-1 {
  font-size:12px;
  text-decoration: none;  
  line-height: 20px;
  font-weight: normal;
color: #666;
  letter-spacing: 0.4px;
 }
 .left-6{
  left: -9px;
 }
 

 html .btn-secondary {
  background-color: #8081ff;
  border-color: #8081ff #8081ff #8081ff;
  color: #FFF;
  --color: #e36159;
  --hover: #e9807a;
  --disabled: #e36159;
  --active: #dd4238;
  padding: 10px 18px;
  font-size: 12px;
}
html .btn-secondary:hover,
html .btn-secondary.hover {
  background-color: #e36159;
  border-color: #ea8b85 #ea8b85 #e36159;
  color: #FFF;
}

.instructor_bg{
  background-position:center center;
   background-size: contain;
   max-height:180px;
  background-image: url(../../assets/img/logo.svg)
}

.vedioplay-icon{
  align-items: center;
  color: #fff;
  position: absolute;
  top: 350px;
  left: 500px;
  z-index: 1;
}
.topicplay-icon{
  align-items: center;
  color: #f56330;
  position: absolute;
  top: 80px;
  left: 120px;
  z-index: 1;
}
.flow-links{
  font-size:10px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;  
  
}
.flow-links:hover{
  color:#f56330
}

.image-content{
  max-height: 124px;
  margin-top: 5px;
  width: 100%;
  margin-left: -8px;
}
.top3{
  margin-top: -16px;
  margin-left: -19px;
}
.top5{
  top: -30px;
}
.module_card_height{
  max-height:140px;
  width: 100%;
  object-fit: cover;
  margin-left: 5px;
}
.custom-instructor-details-1{
  width: 100%;
  height: 120px;
  padding-top: 10px;
  margin-left: 5px;
  
}
.ml-6{
  margin-left: -9px;
}
.custom-instructor-details_1{
  width: 100%;
  height: 175px;
  padding-top: 10px;
  margin-left: 5px;
  margin-bottom: -10px;
}
.custom-instructor-details{
  width: 100%;
  height: 170px;
  overflow: hidden;
  display: flex;
  flex-direction:row;
  margin-left: 5px;
}
.custom-instructor-details-2{
  max-width: 100%;
  height: 160px;
  overflow: hidden;
  margin-left: 4px;
}

.total_topic_search_height{
  width: 100%;
  
}
.ml1{
  position: relative;
  left: -30px;
  top: 9px;
}
.ml2{
  position: relative;
  left: -20px;
  top: 5px;
  
}
.text-uppercase {
  text-transform: uppercase !important;
}
.top-3 {
  top: 3px !important;
}
.top-4{
  margin-top: -15px;
}

.mt3{
  position: absolute;
 left: 190px; 
 top: -5px;
 
}


.module_img{
  min-height: 23vh;
  object-fit: fill;
}

.mb3{
  position: relative;
  top: 38px;
}
.mb4{
  position: relative;
  top: 50px;
}

.bg-subcolor{
  
  padding-left: 5px;
  height: 25px;
  margin-top: -14px;
}
.bg-subcolor_1{
  text-align: center;
  padding-left: 18px;
  height: 55px;
  margin-top: -14px;
  margin-left: auto;
  margin-right: auto;
}
.bg-subcolor_2{
  
  padding-left: 5px;
  height: 25px;
  margin-top: -14px;
  
}
 .card_height{
   max-height: 350px !important;
}

.edit_button{
 float: right;
 margin-right: 15px;
 margin-top: -2px;
 
 
}
.mt2{
  position: absolute;
  right: 40px;
  top: -9px;
}
.mb1{
  margin-top: -6px;
  margin-left: 20px;
}

.top4{
  margin-top: -1px;
  margin-bottom: 18px;
}

.btn_move{
  margin-left:48px;
  
}

.main_heading_color{
  color: #000;
}
.search_box{
  max-width: 100%;
}
.left-7{
  left: -20px;
}
.top-6{
  top: 8px;
}

.custom_topic_card{
  width: 100%;
  max-height: 165px;
}
.topic_flow_image{
  max-height: 105px !important;
  margin-top: 1px;
  margin-left: -15px;
  object-fit: cover;
}

.guru_img_topic{
  width: 100%;
  object-fit: cover;
  height: 145px !important;
}
.justifyspace{
  justify-content: space-between;
}



.guru_course_img{
  min-height: 155px;
  object-fit: cover;

}


.link_color{
  color: #0d6efd !important;
}
.link_color:hover{
  color:#e36159 !important;
 }
.module_desc{
  margin-top: -14px;
}
.bg-light{
  background-color: #fff;
}
.links_active{
  margin-top: -2px;
  justify-content: space-between;
}
.total_topic_search_card{
  max-width: 100%;
  max-height: 155px;
}

.dashboard_video_card{
  max-width: 100%;
  max-height: 180px;
}
.top_search_edit{
  position: absolute;
  top: 10px;
  right: 150px;
}


.faourite_img{
  max-height: 130px;
}
.favourite_instructor_card{
  max-height: 180px;
  max-width: 100%;
}



@media screen and (min-width:100px) and (max-width:400px){
.custom-instructor-details-1{
  width: 100%;
  height: 360px;
  justify-content: center;
  text-align: center;
 
}
.ml1{
  position: relative;
  left: 0px;
  margin-left: auto;
  margin-right: auto;
}
.ml2{
  position: relative;
  left: 0px;
  margin-left: auto;
  margin-right: auto;
}
}


/*===search-box css======*/
.input-box {
  /* position: relative; */
  height: 50px;
  width: 300px;
  background: #fff;
  border-radius: 8px;
  margin-top: 32px;
  /* left: -10px; */
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  border: none;
  padding: 0 155px 0 65px;
  background-color: transparent;
}
.input-box .button {
  right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  background-color: #4070f4;
  cursor: pointer;
}
.input-box .button:active {
  transform: translateY(-50%) scale(0.98);
}

/* Responsive */
@media screen and (max-width: 500px) {
  .input-box {
    height: 50px;
    margin: 0 8px;
  }
  
  .input-box input {
    padding: 0 112px 0 50px;
  }
  .input-box .button {
    right: 12px;
    font-size: 14px;
    padding: 8px 18px;
  }
}

.btn-search{
  background-color: #4070f4;
  color: #fff;
  position: absolute;
  right: 40px;
  top: 4px;
  padding: 7px 10px;
}

.btn-categeory-search{
  background-color: #4070f4;
  color: #fff;
  position: absolute;
  right: 65px;
  top: 4px;
  padding: 7px 10px;  
}
.btn-categeory-sub-search{
  background-color: #4070f4;
  color: #fff;
  position: absolute;
  right: 35px;
  top: 4px;
  padding: 7px 10px;  
}
.fr{
  float: right;
  margin-top: -10px;
  margin-left: 10px;
}