.bank-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1.875rem;
    background-color: var(--glassblue);
    padding: 1.5rem 1.6875rem;
    color: var(--white);
    justify-content: space-between;
    max-width: 436px;
    max-height: 276px;
    min-height: 276px;
    background-image: url('../../assets/images/bankcard-bg-circle.svg');
    background-position: right top;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 3rem;
    &::before{
        content: '';
        background-image: url('../../assets/img/logo.svg');
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: repeat;
        background-position: top left;
    }
    .bank-main{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    .bank-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bank-bottom{
        display: flex;
        flex-direction: column;
        width: 100%;
        h2{
            font-weight: 700;
            font-size: 2rem;
            line-height: 39px;
            letter-spacing: 0.04em;
            text-shadow: 0px 1px 4px rgba(18, 109, 177, 0.58);
            margin-bottom: 1rem;
        }
        p{
            margin-bottom: 0;
            font-weight: 500;
            font-size: 0.9375rem;
            color: #FFFFFF;
            text-shadow: 0px 1px 4px rgba(18, 109, 177, 0.58);
        }
        h4{
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: #FFFFFF;
            text-shadow: 0px 1px 4px rgba(18, 109, 177, 0.58);
            margin-bottom: 0;
        }
    }
}