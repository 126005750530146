.dash-guru-status-card{
    // display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
    // box-shadow: 0px 4px 18px 0px rgba(47, 43, 61, 0.1);
    box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
    color: var(--white);
    // height: 100% !important; ck-07082024
    padding: 10px;
    .dgsc-title{
        font-weight: 300;
        font-size: 1.5rem;
        margin-bottom: 0px;
        text-align: left;
        width: 80%;
        color:var(--secondary800);

    }
    .dgsc-subtitle{
        margin-top: -10px;
       /* display: flex;*/
        min-height: 0.9375rem;
        font-size: 0.75rem;
        margin-bottom: 0.625rem;
        line-height: 50px;
        color: var(--white);
    }
    .dash-icons{
        float: right;
        position: absolute;
        right: 0px !important;
    }
    .dgsc-number{
        

    }
    .view-icon {
        position: absolute;
        top: 20px;
        right: 30px;
    }
}
.count-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.video-poster-bg{
    background-color: var(--primary);
    height: 100%;
    display: flex;
}
.dash-recent-upload-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--primary);
    color: var(--white);
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 2.5rem;
    .dash-recent-info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .dash-recent-info-img{
            display: flex;
            max-width: 12.5rem;
            img{
                border-radius: 5px;
            }
        }
        .dash-recent-info-content{
            width: calc(100% - 245px);
            margin-top: -50px;
            h4{
                font-weight: 600;
                margin-bottom: 1.25rem;
            }
            p{
                color: var(--whitehalf);
                margin-bottom: 0;
            }
        }
    }
}

.dash-recent-upload-card-white{
    display: flex;
    flex-direction: column;
    // width: 100%;
    background-color: var(--white);
    color: var(--black);
    padding: 1rem;
    /*border-radius: 5px;*/
    margin-bottom: 1.5rem;
    .dash-recent-info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .dash-recent-info-img{
            display: flex;
            max-width: 12.5rem;
            img{
                border-radius: 5px;
            }
        }
        .dash-recent-info-content{
            width: calc(100% - 245px);
            margin-top: -50px;
            h4{
                font-weight: 600;
                margin-bottom: 1.25rem;
            }
            p{
                color: var(--whitehalf);
                margin-bottom: 0;
            }
        }
    }
}



.viewslikes{
    background-color: var(--bright);
    color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    border-radius: 5px;
    margin-left: 0.75rem;
    margin-bottom: 1.5rem;
    text-align: center;
}
.views-analytic-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bright);
    margin-bottom: 2.5rem;
    border-radius: 5px;
    .views-analytic-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--secondary800);
        padding: 1.5rem 2.1875rem;
        position: relative;
        border-radius: 5px;
        .soryby{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dropdown{
                margin-left: 0.75rem;
            }
        }
    }
    .views-analytic-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 400px;
    }
}
.report-analytic-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2.5rem;
    border-radius: 5px;
    /*background-color: var(--secondary);*/
    &.report-analytic-secondary{
        background-color: var(--green400);
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 3rem;
        padding-bottom: 1.5rem;
        &.last{
            margin-bottom: 0;
            border-radius: 5px 5px 0 0;
        }
    }
    .report-analytic-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 1.875rem;
        .soryby{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dropdown{
                margin-left: 0.75rem;
            }
        }
    }
    .report-analytic-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 400px;
    }


   
}

@media screen and (max-width: 767px) {
    .dash-guru-status-card{
        height: 100px;
        margin-bottom: 1.25rem;
        .dgsc-title{
            font-weight: 500;
            font-size: 1rem;
            margin-bottom: 0px;
        }
    }
    .dash-recent-upload-card{
        padding: 1.25rem;
        margin-bottom: 2.5rem;
        .dash-recent-info{
            .dash-recent-info-img{
                display: flex;
                max-width: 100%;
                margin-bottom: 1.25rem;
                img{
                    border-radius: 5px;
                }
            }
            .dash-recent-info-content{
                width: 100%;
                h4{
                    font-weight: 600;
                    margin-bottom: 0.625rem;
                }
                p{
                    color: var(--whitehalf);
                    margin-bottom: 1.25rem;
                }
            }
        }
    }


    .dash-recent-upload-card-white{
        padding: 1.25rem;
        margin-bottom: 2.5rem;
        .dash-recent-info{
            .dash-recent-info-img{
                display: flex;
                max-width: 100%;
                margin-bottom: 1.25rem;
                img{
                    border-radius: 5px;
                }
            }
            .dash-recent-info-content{
                width: 100%;
                h4{
                    font-weight: 600;
                    margin-bottom: 0.625rem;
                }
                p{
                    color: var(--whitehalf);
                    margin-bottom: 1.25rem;
                }
            }
        }
    }



    


    .views-analytic-card{
        margin-bottom: 2.5rem;
        .views-analytic-head{
            flex-direction: column;
            padding: 1.25rem 1.25rem;
            .soryby{
                margin-top: 0.9375rem;
                .dropdown{
                    margin-left: 0.625rem;
                }
            }
        }
    }
    .report-analytic-card{
        margin-bottom: 2.5rem;
        .report-analytic-head{
            flex-direction: column;
            padding: 1.25rem 1.25rem;
            .soryby{
                margin-top: 0.9375rem;
                .dropdown{
                    margin-left: 0.625rem;
                }
            }
        }
    }
}


.dash-icon-c{
    font-size: 50px;
    color: var(--primary);
}
