.search-guru-card{
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    .search-guru-image{
        display: flex;
        flex-direction: column;
    }
    .search-guru-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        color: var(--whitehalf);
        padding-left: 1.25rem;
        .search-guru-content-title{
            color: var(--white);
            margin-bottom: 0.75rem;
            line-height: 1.625rem;
        }
        .search-guru-content-cate{
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 1.25rem);
            margin-left: -0.625rem;
            margin-right: -0.625rem;
            margin-bottom: 1rem;
            .search-guru-content-cate-items{
                display: flex;
                align-items: center;
                width: 50%;
                padding-left: 0.625rem;
                padding-right: 0.625rem;
                margin-bottom: 0.75rem;
                .cate-items-icon{
                    display: flex;
                    margin-right: 0.625rem;
                    color: var(--whitehalf);
                }
                .cate-items-title{
                    font-size: 1.125rem;
                    font-weight: 500;
                    color: var(--whitehalf);
                }
            }
        }
        .search-guru-content-description{
            max-width: 80%;
        }
    }
}