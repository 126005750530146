.portfolio-list {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.sort-destination {
    overflow: hidden;
}
.sort-destination .isotope-item {
    min-width: 1px;
}
.thumb-info {
    display: block;
    position: relative;
    text-decoration: none;
    max-width: 100%;
    background-color: #fff;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    border-radius: 0 !important;
}
.thumb-info.custom-thumb-info-style-1 {
    border: none;
}

.thumb-info .thumb-info-wrapper {
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    overflow: hidden;
    display: block;
    position: relative;
}
.thumb-info:hover img {
    transform: scale(1.1,1.1);
}
.thumb-info img {
    transition: all .3s ease;
    position: relative;
    width: 100%;
}
.thumb-info.custom-thumb-info-style-1:hover .thumb-info-caption {
    background-color: #4dbb6d !important;
}
.thumb-info.custom-thumb-info-style-1:hover .thumb-info-caption {
    background: #4dbb6d !important;
}
.thumb-info.custom-thumb-info-style-1 .thumb-info-caption {
    position: relative;
    display: block;
    transition: ease background 300ms, ease width 300ms;
}
.thumb-info.custom-thumb-info-style-1:hover .thumb-info-caption .custom-thumb-info-title span {
    color: #FFF !important;
}
.thumb-info.custom-thumb-info-style-1 .thumb-info-caption .custom-thumb-info-title .custom-thumb-info-name {
    display: block;
}
.thumb-info.custom-thumb-info-style-1:hover .thumb-info-caption .custom-arrow .right-icon{
    width: 105px;
}
.thumb-info.custom-thumb-info-style-1 .thumb-info-caption .custom-arrow .right-icon{
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    transition: ease all 300ms;
}
.custom-thumb-info-desc{
    margin-left: 10px;
}