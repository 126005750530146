.pdf-details{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .pdf-details-icon{
        margin-right: 1.25rem;
    }
    .pdf-details-info{
        h4{
            color: var(--white);
        }
        p{
            margin: 0;
            font-size: 0.75rem;
            color: var(--whitehalf);
        }
    }
}
@media screen and (max-width: 767px) {
    .guideline-img{
        margin-top: 1.25rem;
    }    
}