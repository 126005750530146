/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
 font-family: 'Inter', sans-serif; */
 /*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
 /*font-family: 'Montserrat', sans-serif;*/
 
 @import '~react-toastify/dist/ReactToastify.css';
 @import '~react-phone-number-input/style.css';
 html{
   font-size: 16px;
 }
 
 body{
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   line-height: 1.5;
 }
 *, ::after, ::before {
   box-sizing: border-box;
 }
 .main_wrapper img {
  width: 100%;
}
 .container{
   padding-left: 0.9375rem;
   padding-right: 0.9375rem;
   width: 100%;
   max-width: 1300px;
   margin-left: auto;
   margin-right: auto;
 }
 .container-fluid{
   padding-left: 0.9375rem;
   padding-right: 0.9375rem;
   margin-left: auto;
   margin-right: auto;
   max-width: 1440px;
 }
 h1, h2, h3, h4, h5, h6{
   margin: 0 0 0.625rem;
 }
 h1{
   font-weight: 700;
   font-size: 2.5rem;
 }
 h2{
   font-size: 2rem;
 }
 h3{
   font-size: 1.5rem;
 }
 h4{
   font-size: 1.125rem;
 }
 p{
   margin-top: 0;
   font-weight: 300;
 }
 
 /* Track */
 ::-webkit-scrollbar {
   width: 0.375rem;
   height: 0.375rem;
 }
 ::-webkit-scrollbar-track {
   background-color: var(--secondary100);
 }
 /* Handle */
 ::-webkit-scrollbar-thumb {
   background-color: var(--secondary200);
 }
 
 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background-color: #555;
 }
 @media screen and (min-width: 576px) {
   .swiper-container {
     width: 576px;
   }
 }
 
 @media screen and (min-width: 768px) {
   .swiper-container {
     width: 768px;
   }
 }
 @media screen and (min-width: 768px) and (max-width: 1279px) {
   html{
     font-size: 14px;
   }
 }
 @media screen and (max-width: 767px) {
   html{
     font-size: 14px;
   }
   h1{
     font-weight: 700;
     font-size: 2rem;
   }
   h2{
     font-size: 1.5rem;
   }
   h3{
     font-size: 1.25rem;
   }
   h4{
     font-size: 1rem;
   }
   .slider-main{
     padding-right: 3.75rem;
   }
 }

 
 
 .about .content h3{
  margin:20px 0 !important;
 }
 
 @media screen and (max-width: 375px) {
   html{
     font-size: 12px;
   }
 }
 .section-title h2{
  display: -ms-inline-grid;
 }
 .section-title{
 /* display: contents !important;*/
}
.opacity-30{
  opacity:0.3;
}