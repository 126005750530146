Table tbody tr{
    text-align: center;
    justify-content: center;
    line-height: 10px;
    align-items: center;
}
td {
   /* height: 80px;
    width: 160px; */
    text-align: center;
    vertical-align: middle;
  }
.checkbox{
    display: flex;
    line-height: 20px;
    font-size: 16px;
    min-width: 150px;
}

.bg-table{
background-color:#eae8e8
}

.table{
    background-color:#fff;
        padding:10px
}
.bg-th{
background-color:#eae8e8
}