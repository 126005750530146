.category-button-group{
    position: relative;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    .swiper{
        overflow: inherit;
        .swiper-wrapper{
            margin-bottom: 1rem;
            .swiper-slide{
                width: auto;
            }
        }
    }
    .slider-arrow-up{
        margin-bottom: 1rem;
    }
    .sub-cat-button-wrapper{
        position: relative;
    }
    .category-dropdown{
        width: 100%;
        min-width: 13.75rem;
        max-width: 13.75rem;
        position: absolute;
        padding-top: .5rem;
        display: none;
        &.active{
            display: block;
        }
        .category-dropdown-main{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 5px;
        }
        .category-dropdown-item{
            display: flex;
            flex-direction: column;
            &:first-child{
                .btn{
                    border-radius: 5px 5px 0 0;
                }
            }
            &:last-child{
                .btn{
                    border-radius: 5px 5px 5px 5px;
                }
            }
            .btn{
                font-size: 1rem;
                font-weight: 400;
                border-bottom: 1px solid rgba(152, 148, 148, 0.14);
                border-radius: 0;
                padding: 0.625rem 1.25rem;
                text-transform: inherit;
                justify-content: flex-start;
                &:hover,
                &.active{
                    background-color: var(--primary);
                    border-color: var(--primary);
                    color: var(--white);
                    font-weight: 700;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .category-button-group{
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        .category-dropdown{
            width: 100%;
            min-width: 13.75rem;
            max-width: 13.75rem;
            .category-dropdown-item{
                .btn{
                    padding: 0.625rem 1rem;
                }
            }
        }
    }
}
