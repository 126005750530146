.premium-account-subscribe-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: var(--white);
    padding: 1.25rem 1rem;
    border-radius: 5px;
    position: relative;
    margin-top: 18%;
    /*background-image: url('../../assets/img/logo.svg');*/
    background-repeat: no-repeat;
    background-position: right bottom;

    .image-element{
        justify-content: center;
        margin-bottom: 1.875rem;
        margin-top: 38%;
    }
    h1{
        font-size: 3.375rem;
        line-height: 1;
        margin-top: 35%;;
    }
}
@media screen and (max-width: 767px) {
    .premium-account-subscribe-wrap{
        h1{
            font-size: 2.5rem;
        }
    }    
}