.favourite-guru-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  position: relative;
  margin-bottom: 2rem;

  .favourite-guru-item-image {
    .image-element {
      img {
        border-radius: 5px;
      }
    }
  }

  .favourite-guru-item-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
  }
/*
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--blackhalf);
  }

  &:hover {
    &:before {
      background: none;
    }
  }
  */
}

