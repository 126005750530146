.topic-video-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
    background-color:var(--white);
    padding: 15px;
    padding-top: 0px;
    transition: all 1.2 ease;
    box-shadow: 0px 0px 8px #ccc;
    margin-left: 10px;
    margin-right: 10px;
    .video-card{
        .video-poster{
            .image-element{
                border-radius: 5px;
                overflow: hidden;
            }
        }
    }
    .topic-video-details{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 0.10rem;
        .avatar{
            margin-right: 0.75rem;
        }
        .topic-video-details-content{
            width: calc(100% - 3.25rem);
            color: var(--whitehalf);
            .topic-video-details-content-title{
                font-size: 1.5rem;
                line-height: 1.75rem;
                margin-bottom: 0.5rem;
                color: var(--white);
            }
            .topic-video-details-content-by{
                font-size: 1.125rem;
                font-weight: 400;
                margin-bottom: 0.5rem;
            }
            .topic-video-details-content-short-desc{
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }
            .topic-video-details-content-views{
                font-size: 0.75rem;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
    }
    &.topic-video-secondary{
        .topic-video-details{
            .topic-video-details-content{
                .topic-video-details-content-title{
                    font-size: 1.25rem;
                }
                .topic-video-details-content-by{
                    font-size: 0.875rem;
                }
                .topic-video-details-content-short-desc{
                    font-size: 0.8125rem;
                }
                .topic-video-details-content-views{
                    font-size: 0.875rem;
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    .topic-video-card{
        margin-bottom: 1.25rem;
        .topic-video-details{
            margin-top: 0.625rem;
            .avatar{
                margin-right: 0.625rem;
            }
            .topic-video-details-content{
                .topic-video-details-content-title{
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.cate_bg{
    color: #e36159 !important;
    max-width: 100%;
    padding: 2px;
}
.heart_count{
    float: right;
    line-height: 16px;
    margin-right: -25px;
}