/*========hero section css===========*/
section.section {
    padding: 50px 0;
  }
  .custom-section-shape-background {
    position: relative;
    overflow: hidden;
  }
  .m-0 {
    margin: 0;
  }
  .border-0 {
    border: 0 ;
  }
  .z-index-3 {
    z-index: 3;
  }
  .box-shadow-3:not(.box-shadow-hover) {
    box-shadow: 0 30px 60px -30px rgba(0,0,0,.45);
  }
  html .text-color-primary, html .text-primary {
    color: #8081ff;
  }
  .font-weight-medium {
    font-weight: 500;
  }
  .text-4 {
    font-size: 12px;
  }
  .positive-ls-3 {
    letter-spacing: 2.5px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .font-weight-bold {
    font-weight: 700;
  }
  .text-transform-none {
    text-transform: none;
  }
  .line-height-2 {
    line-height: 1.2;
  }
  .text-9 {
    font-size: 2.5em;
  }
  .custom-font-size-1 {
    font-size: 1.4em;
  }
  .line-height-7 {
    line-height: 1.7;
  }
  
  .position-relative {
    position: relative !important;
  }
  .list.list-icons {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
  }
  .list.list-icons.list-icons-lg.list-icons-style-2 li {
    padding-top: 8px;
    padding-left: 42px;
  }
  .list.list-icons li {
    position: relative;
  }
  html .text-color-dark, html .text-dark {
    color: #212529 !important;
    
  }
  .font-weight-semibold, .font-weight-semi-bold {
    font-weight: 600 !important;
  }
  li {
    line-height: 24px;
    display:inline; 
  }
  .list li {
    margin-bottom: 13px;
    display:inline; 
  }
  
  .check-icon{
     background-color: #8081ff;
     color: #fff;
  }