.video-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .video-poster{
        display: flex;
        flex-direction: column;
        width: 100%;
        object-fit: contain;
        position: relative;
        align-items: center;
        justify-content: center;
        .image-element{
            img.img-fluid{
                max-height: 310px;
                object-fit:contain;
               
            }
        }
        img{
            width: 100%;
            border-radius: 5px;
            margin-left: 10px;
        }
        .video-play{
            position: absolute;
            top: 45%;
        }
        .progress-bar-align{
            margin-top: -65px;
            margin-right:auto;
        }
        .video-duration{
            position: absolute;
            background-color: var(--secondary200);
            color: var(--white);
            font-size: 0.75rem;
            border-radius: 5px;
            padding: 0 0.25rem;
            line-height: 1rem;
            right: 0.625rem;
            bottom: 0.625rem;
            &.large{
                font-size: 1.5rem;
            }
        }
        .progress-bar-wrapper {
            position: absolute;
            bottom: 0;
        }
    }
    .video-caption{
        margin-top: 1.25rem;
        .video-title{
            font-size: 0.95rem;
            font-weight: 500;
            color: var(--white);
            margin-bottom: 0.5rem;
        }
        .video-desc{
            font-size: 1rem;
            font-weight: 400;
            color: var(--white);
            opacity: 0.5;
            margin: 0;
            max-width: 75%;
        }
    }
}
.hmaboutus-block{
    .video-card{
        .video-caption{
            .video-desc{
                max-width: 100%;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .hmaboutus-block{
        margin-bottom: 2rem;
    }
}
