/*--------------------------------------------------------------
# Trainers
--------------------------------------------------------------*/
.member {
    text-align: center;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #eef0ef;
  }
  
  .member img {
    margin: -1px -1px 30px -1px;
  }
  
  .member .member-content {
    padding: 0 20px 20px 20px;
  }
  
  .member h4 {
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 18px;
  }
  
  .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
  }
  
  .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: #aaaaaa;
  }
  
  .member .social {
    margin-top: 15px;
  }
  
  .member .social a {
    color: #7e9486;
    transition: 0.3s;
  }
  
  .member .social a:hover {
    color: #8081ff;
  }
  
  .member .social .trainer-social-links {
    font-size: 18px;
    margin: 0 2px;
  }

  .instru-img{
    height: 150px;
    overflow: hidden;
  }