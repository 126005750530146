.avatar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    img{
        max-width: 100%;
        border-radius: 50%;
        height: auto;
    }
    &.avatar-48{
        max-width: 3rem;
        min-width: 3rem;
        img{
            width: 3rem;
            height: 3rem;
        }
    }
    &.avatar-40{
        max-width: 2.5rem;
        min-width: 2.5rem;
        img{
            width: 2.5rem;
            height: 2.5rem;
        }
    }
    &.avatar-100{
        max-width: 6.25rem;
        min-width: 6.25rem;
        img{
            width: 6.25rem;
            height: 6.25rem;
        }
    }
}