.toggle-switch {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 35px; 
  input[type="checkbox"] {
    display: none;
 
  }
  .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 20px;
    border: 1px var(--black);
    
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
    &::before {
      position: absolute;
      content: "";
      left: 2px;
      top: 2px;
      width: 30px;
      height: 30px;
      background-color: var(--primary);
      border-color: var(--black);
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
  }
  input[type="checkbox"]:checked {
    & + .switch {
      background-color: var(--switchActiveColor);
      &::before {
        transform: translateX(105px);
      }
    }
  }
}
