.basic-finance-wrapper{
    /*display: flex;*/
    width: 100%;
    flex-wrap: wrap;
    .basic-finance-left{
        display: flex;
        flex-direction: column;
        background-color: var(--primary);
        border-radius: 5px 0 0 5px;
        padding: 1.5rem 1rem;
        height: 100%;
        h4{
            margin-bottom: 0;
            color: var(--whitehalf);
            font-size: 1.25rem;
        }
        p{
            color: var(--white70);
            font-size: 0.875rem;
        }
        .basicfinance-list{
            display: flex;
            align-items: center;
            margin-top: 0.625rem;
            .icon{
                margin-right: 0.625rem;
                background-color: #ffffff;
            }
        }
        .head-to-text{
            color: #ffffff;
            font-size: 14px;
        }
        svg{
            color: var(--primary);
        }
    }
    .basic-finance-right{
        /*display: flex;*/
        background-color: var(--white);
        border-radius: 0 5px 5px 0;
        height: 100%;
        padding: 1.5rem 1rem;
        .basic-finance-right-video{
            display: flex;
            flex-direction: column;
        }
        .basic-finance-right-content{
            color: var(--black70);
            h3{
                color: var(--secondary);
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .basic-finance-wrapper{
        .basic-finance-left{
            height: auto;
            padding: 1.5rem 1rem;
        }
        .basic-finance-right{
            height: auto;
            padding: 1.5rem 1rem;
            .basic-finance-right-content{
                margin-top: 1.25rem;
            }
        }
    }
}
