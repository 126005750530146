.top-ten-video-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2.5rem;
    position: relative;
    cursor: pointer;
    .tag-ribbon{
        position: absolute;
        top: 0;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        .tag-ribbon-icon{
            position: absolute;
            top: 3px;
        }
    }
    .video-card{
        .video-poster{
            .image-element{
                border-radius: 5px;
                overflow: hidden;
            }
        }
    }
    .top-ten-video-details{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1.25rem;
        .top-ten-video-details-number{
            margin-right: 0.75rem;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: var(--primary);
            font-size: 5rem;
            line-height: 1;
        }
        .top-ten-video-details-content{
            width: calc(100% - 3.75rem);
            color: var(--whitehalf);
            .top-ten-video-details-content-title{
                font-size: 1.5rem;
                line-height: 1.75rem;
                margin-bottom: 0.5rem;
                color: var(--white);
            }
            .top-ten-video-details-content-by{
                font-size: 1.125rem;
                font-weight: 400;
                margin-bottom: 0;
            }
            .top-ten-video-details-content-short-desc{
                font-size: 1rem;
                margin-bottom: 0;
            }
            .top-ten-video-details-content-views{
                font-size: 0.75rem;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
    }
}
@supports (-webkit-text-stroke: 2px var(--primary)) {
    .top-ten-video-details-number {
      -webkit-text-stroke: 2px var(--primary);
      -webkit-text-fill-color: transparent;
    }
}
.top-ten-videos-section{
    background-color: var(--green600);
    background-position: right center;
    background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
    .top-ten-video-card{
        margin-bottom: 1.25rem;
        .top-ten-video-details{
            margin-top: 0.625rem;
            .top-ten-video-details-number{
                margin-right: 0.625rem;
                font-size: 4rem;
            }
            .top-ten-video-details-content{
                width: calc(100% - 3.125rem);
                .top-ten-video-details-content-title{
                    margin-bottom: 0px;
                }
            }
        }
    }
}