.status{
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    height: 30px;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    &.status-approved{
        background-color: var(--approved);
        color: var(--white);
    }
    &.status-rejected{
        background-color: var(--reject);
        color: var(--white);
    }
    &.status-draft{
        background-color: var(--draft);
        color: var(--white);
    }
    &.status-pending{
        background-color: var(--pending);
        color: var(--white);
    }
}