.join-guru-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.875rem 1.875rem 0;
    background-color: var(--green700);
    border-radius: 5px;
    position: relative;
    background-image: url('../../assets/img/logo.svg');
    background-position: left top;
    background-repeat: no-repeat;
    &::after{
        content: '';
        opacity: 0.3;
        background-image: url('../../assets/img/logo.svg');
        background-position: right bottom;
        background-repeat: no-repeat;
        width: 309px;
        height: 272px;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    h3{
        text-transform: uppercase;
    }
    .list-ul{
        color: inherit;
    }
}

@media screen and (max-width: 767px) {
    .join-guru-wrapper{
        padding: 1.25rem;
        &::after{
            width: 100%;
        }
    }
}