.watch-next-video-item-card{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    .watch-next-video-item-poster{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        .image-element{
            align-items: center;
            justify-content: center;
        }
    }
    .watch-next-video-item-details{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        border-radius: 5px;
        padding: 0.5rem;
        justify-content: flex-end;
        .watch-next-video-item-detail-title{
            margin-bottom: 0;
            line-height: 1.875rem;                  
            text-overflow: ellipsis; 
            overflow: hidden; 
            white-space: nowrap;
            color: white;
        }
        .watch-next-video-item-detail-desc{
            margin-bottom: 0;         
            text-overflow: ellipsis; 
            overflow: hidden; 
            white-space: nowrap;  
            color: white; 
        }
        .watch-next-video-item-views{
            color: var(--whitehalf);
            font-size: 0.75rem;
        }
        .watch-next-video-item-duration{
            display: flex;
            justify-content: flex-end;
            background-color: #818181;
            border-radius: 5px;
            padding: 0 0.5rem;
            font-size: 0.75rem;
            line-height: 1rem;
            text-align: center;
        }
    }
}
@media screen and (max-width: 767px) {
    .watch-next-video-item-card{
        .watch-next-video-item-details{
            .watch-next-video-item-detail-title{
                line-height: 1.25rem;
            }
        }
    }
}