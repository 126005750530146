.search-wrapper{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    margin-top: 6px;
    
    .form-field{
        width: 100%;
        margin-bottom: 0;
        &.form-field-select{
            max-width: 10rem;
            &::after{
                border-color: var(--black200);
            }
            select{
                &.form-control{
                    padding: 0 0.875rem;
                    background-color: var(--light);
                    border-color: var(--light);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    color: var(--black200);
                    font-weight: 600;
                }
            }
            + .form-field{
                .form-control{
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        } 
        .form-control{
            width: 100%;
            background-color: var(--light200);
            border-color: var(--light200);
            border-radius: 5px;
            height: 2.25rem;
            padding: 0.375rem 2.5rem 0.375rem 0.875rem;
            color: var(--black200);
            border-color: var(--light200);
            font-size: 0.75rem;
        }
    }
    .btn{
        position: absolute;
        right: 1rem;
        top: 50px;
        
    }
}