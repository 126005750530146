.navbar{
    display: flex;
    align-items: center;
    margin-right: auto;
    .navbar-nav{
        display:flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        .nav-item{
            display:flex;
            align-items: center;
            text-decoration: none;
            padding-left: 2.05rem;
            padding-right: 0.95rem;
            .nav-link{
                text-decoration: none;
                color: var(--black);
                // font-weight: 500;
                font-size: 1.032rem;
                text-transform: capitalize;
                cursor: pointer;
                &:hover{
                    color: var(--inactive);
                }
                &.be-a-guru {
                    color: var(--inactive);
                }
                .nav-text{
                    text-decoration: none;
                    color: var(--black);
                }
                .active{
                    color: var(--inactive);
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .navbar{
        .navbar-nav{
            flex-direction: column;
            width: 100%;
            .nav-item{
                display: flex;
                align-items: center;
                padding-left: 0.625rem;
                padding-right: 0.625rem;
                margin-bottom: 0.625rem;
                .nav-link{
                    text-decoration: none;
                    color: var(--black);
                    font-weight: 500;
                    font-size: 1rem;
                    text-transform: uppercase;
                    cursor: pointer;
                    &:hover{
                        color: var(--inactive);
                    }
                }
            }
        }
    }
}

.float-end{
    float: right;
}

@media screen and (max-width: 460px) {

    .navbar{
        margin: inherit;
       
    }
    .h-buttons{
        button{
            margin-top: 10px;
            width: 100%;
            margin-left: 0px;
        }
        .btn + .btn{
            margin-left: 0px;
        }
        
    }

    .header-main .logo_Main {
        height: 52px;
        
    }
}

