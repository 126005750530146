.font-weight-medium {
  font-weight: 500 !important;
}
.text-4 {
  font-size: 1.2em !important;
}


.line-height-9 {
  line-height: 1.9 !important;
}
.text-3-4, .text-3-5 {
  font-size: 1.1em !important;
}

.my-5-5 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}
.counters .counter {
  text-align: center;
}
html .text-color-secondary, html .text-secondary {
  color: #8081ff !important;
}
.counters strong {
  display: block;
  font-weight: 700;
  line-height: 50px;
}
.text-13 {
  font-size: 4em !important;
}
html .text-color-primary, html .text-primary {
  color: #ec4f4f !important;
}