.video-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .video-poster{
        display: flex;
        flex-direction: column;
        width: 100%;
        object-fit: contain;
        position: relative;
        align-items: center;
        justify-content: center;
        .image-element{
            img.img-fluid{
                max-height: 410px;
                object-fit:contain;
            }
        }
        img{
            width: 100%;
            border-radius: 5px;
            
        }
        .video-play{
            position: absolute;
            top: 60px;
        }

        .dashboard-video-play{
            position: absolute;
            top: 70px;
        }
        .progress-bar-align{
            margin-top: -65px;
            margin-right:auto;
        }
        .video-duration{
            position: absolute;
            background-color: var(--secondary200);
            color: var(--white);
            font-size: 0.75rem;
            border-radius: 5px;
            padding: 0 0.25rem;
            line-height: 1rem;
            right: 0.625rem;
            bottom: 0.625rem;
            &.large{
                font-size: 1.5rem;
            }
        }
        .progress-bar-wrapper {
            position: absolute;
            bottom: 0;
        }
    }
    .video-caption{
        margin-top: 1.25rem;
        .video-title{
            font-size: 0.95rem;
            font-weight: 500;
            color: var(--white);
            margin-bottom: 0.5rem;
        }
        .video-desc{
            font-size: 1rem;
            font-weight: 400;
            color: var(--white);
            opacity: 0.5;
            margin: 0;
            max-width: 75%;
        }
    }
}


.video-card-dashboard{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .video-poster{
        display: flex;
        flex-direction: column;
        width: 100%;
        object-fit: contain;
        position: relative;
        align-items: center;
        justify-content: center;
        .image-element{
            img.img-fluid{
                max-height: 410px;
                object-fit:contain;
            }
        }
        img{
            // width: 180px;
            // height:180px;
            border-radius: 5px;
        }
        .video-play{
            position: absolute;
            // top: 60px;
        }

        .dashboard-video-play{
            position: absolute;
            top: 70px;
        }
        .progress-bar-align{
            margin-top: -65px;
            margin-right:auto;
        }
        .video-duration{
            position: absolute;
            background-color: var(--secondary200);
            color: var(--white);
            font-size: 0.75rem;
            border-radius: 5px;
            padding: 0 0.25rem;
            line-height: 1rem;
            right: 0.625rem;
            bottom: 0.625rem;
            &.large{
                font-size: 1.5rem;
            }
        }
        .progress-bar-wrapper {
            position: absolute;
            bottom: 0;
        }
    }
    .video-caption{
        margin-top: 1.25rem;
        .video-title{
            font-size: 0.95rem;
            font-weight: 500;
            color: var(--white);
            margin-bottom: 0.5rem;
        }
        .video-desc{
            font-size: 1rem;
            font-weight: 400;
            color: var(--white);
            opacity: 0.5;
            margin: 0;
            max-width: 75%;
        }
    }
}

.hmaboutus-block{
    .video-card{
        .video-caption{
            .video-desc{
                max-width: 100%;
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .hmaboutus-block{
        margin-bottom: 2rem;
    }
    video-card .video-poster img {
        border-radius: 5px;
     width: 100% !important;
     height: 180px !important;
     padding: 0 5px !important;
   }
    
}

@media screen and (max-width: 648px) {
    .video-card .video-poster img {
       border-radius: 5px;
        width: 100% !important;
        height: 180px !important;
        padding: 0 5px !important;
      }
      .col-12.text-center.dashboard-share-icon {
        padding-left: 18%;
      }
      .input-share-height {
        width: 101%;
        border: 1px solid #cecece;
        padding: 5px 5px 0px 5px;
        display: inline-table;
        min-height: fit-content;
        max-height: fit-content;
      }
      .w-1l0{
        width: 100% !important;
        display: block;
      }
      .pad-l-22{padding-left: 22%;} 
}
.col-12 col-md-8 h5{
    margin: 10px;
}
.dashboard_video_card img{
    width: 100% !important;
    max-width: 100% !important;
}
.dashboard-video-play {
    position: absolute;
    top: 45%;
    left: 40%;
  }
  @media screen and (min-width: 768px) {
  .video-card .video-poster img {
    border-radius: 5px;
  width: 100% !important;
//   height: 180px !important;
  padding: 0 5px !important;
}
  }