.notice-card-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    .notice-main{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--bright);
        padding: 3.75rem 1.875rem;
        border-radius: 5px;
        background-position: top left;
        background-repeat: no-repeat;
        p{
            color: var(--whitehalf);
        }
    }
}