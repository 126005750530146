.modal-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 55px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    &.modal-light{
        .modal-content{
            background-color: var(--white);
            color: var(--black);
            border-radius: 5px;

        }
    }

    .backdrop {
        background-color: rgba(7, 30, 26, 0.8);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        height: 100% !important;
    }
    .modal-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        max-width: 800px;
        position: relative;
        &.modal-content-small{
            max-width: 550px;
            min-height: 40vh;
        }
        &.modal-content-medium{
            max-width: 660px;
            padding: 3.125rem;
        }
        &.modal-content-medium-extra{
            max-width: 920px;
        }
        &.modal-content-large{
            max-width: 1240px;
            padding: 3.125rem;
        }
        .modal-body{
            width: 100%;
            max-height: 80vh;
            overflow-y: auto left;
            overflow-x: hidden;

            &.no-scroll {
                max-height: initial;
            }
        }
        .modal-close{
            cursor: pointer;
            position:absolute;
            /*left: 31.0375rem;*/
            left:95%;
            margin-bottom:-20px;
            top: -1.315rem;
            color: var(--white);
            background-color: var(--primary);
            font-size: 1.5rem;
            z-index:1000000;
            border-radius: 50%;
        }
    }
    &.modal-signup-right-area{
        position: absolute;
        z-index: 1000000;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding-left: 4.6875rem;
        padding-right: 4.6875rem;
        .modal-content{
            border-radius: 5px;
            background-color: var(--white);
            color: var(--black);
            max-width: 100%;
            width: 30%;
        
        }
        &.modal-secondary >{
            .modal-content{
                background-color: var(--bright);
                color: var(--white);
            }
        }
    }


    &.modal-white{
        .modal-content{
            
            background-color: var(--white);
           
        
        }
    }

    &.modal-30-per{
        .modal-content{
            border-radius: 5px;
            background-color: var(--white);
            color: var(--black);
            max-width: 100%;
            width: 30%;
        
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1159px) {
    .modal-wrapper{
        .modal-content{
            max-width: 90%;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
            &.modal-content-medium{
                max-width: 90%;
                padding: 0.9375rem;
            }
            &.modal-content-large{
                max-width: 90%;
                padding: 0.9375rem;
            }
            .modal-body{
                width: 100%;
            }
            .modal-close{
                cursor: pointer;
                position: absolute;
                color: var(--white);
                font-size: 1.5rem;
            }
        }
        &.modal-signup-right-area{
            position: fixed;
            z-index: 1000000;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            .modal-content{
                border-radius: 5px;
                background-color: var(--white);
                color: var(--black);
                max-width: 100%;
                width: 30%;    
                padding: 2.5rem 1.5rem;
            }
            &.modal-secondary >{
                .modal-content{
                    background-color: var(--bright);
                    color: var(--white);
                }
            }
        }

        &.modal-otp-right-area{
            position: fixed;
            z-index: 1000000;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            .modal-content{
                border-radius: 5px;
                background-color: var(--white);
                color: var(--black);
                max-width: 100%;
                width: 40%;    
                padding: 2.5rem 1.5rem;
            }
            &.modal-secondary >{
                .modal-content{
                    /*background-color: var(--bright);*/
                    color: var(--white);
                }
            }
        }

    }
}
@media screen and (max-width: 767px) {
    .modal-wrapper{
        .modal-content{
            max-width: 90%;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
            &.modal-content-small{
                max-width: 90%;
            }
            &.modal-content-medium{
                max-width: 90%;
                padding: 0.9375rem;
            }
            &.modal-content-large{
                max-width: 90%;
                padding: 0.9375rem;
            }
            .modal-body{
                width: 100%;
            }
            .modal-close{
                cursor: pointer;
                position: absolute;
                color: var(--white);
                font-size: 1.5rem;
            }
        }
        &.modal-signup-right-area{
            position: fixed;
            z-index: 1000000;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            .modal-content{
                border-radius: 5px;
                background-color: var(--white);
                color: var(--black);
                width: 100%;
                padding: 2.5rem 1.5rem;
            }
            &.modal-secondary >{
                .modal-content{
                    background-color: var(--bright);
                    color: var(--white);
                }
            }
        }
        &.modal-30-per{
            .modal-content{
                border-radius: 5px;
                background-color: var(--white);
                color: var(--black);
                max-width: 100%;
                width: 90%;
            
            }
        }
    }
}


.bg_free{
  background-color: #01BFBD;;
}

