.form-radio{
    display: flex;
    margin-bottom: 1.875rem;
    position: relative;
    flex-wrap: wrap;
    .form-field{
        width: auto;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .form-control{
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    .circle{
        width: 2rem;
        height: 2rem;
        background-color: transparent;
        border: 2px solid var(--primary);
        border-radius: 50%;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &.circle-active{
            background-color: var(--primary);
            border-color: var(--primary);
        }
    }
    .form-radio-label{
        font-weight: 400;
        font-size: 1.125rem;
        color: var(--black);
        width: calc(100% - 3rem);
        margin-top: 2px;
        cursor: pointer;
    }
    &.form-radio-secondary{
        padding: 0.9375rem;
        background-color: transparent;
        border: 1px solid var(--primary);
        border-radius: 5px;
        &.active{
            border-color: var(--primary500);
            background-color: var(--primary500);
            .form-radio-label{
                color: var(--black);
                opacity: 1;
                font-weight: 600;
            }
        }
        .form-radio-label{
            opacity: .7;
        }
    }
}