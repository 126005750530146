.form-field{
    position: relative;
    &.form-field-file{
        margin-right: 0.9375rem;
        &.form-field-tertiary{
            margin-bottom: 0;
            .upload-content{
                width: 100%;
                height: 8.375rem;
                background-color: var(--secondary500);
                color: var(--light800half);
                border: 1px dashed #576562;
                .icon{
                    border: 0;
                }

            }
        }
    }
    &.profile-change-picture {
        .form-label {
            text-decoration: underline;
            color: var(--primary);
        }
    }
    &.text-black {
        .form-label {
            color: var(--black70);
            text-decoration: underline;
        }
    }
    .upload-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--blackhalf);
        width: 9rem;
        height: 9rem;
        position: relative;
        background-color: #FBFBFB;
        border: 1px dashed var(--gray500);
        border-radius: 5px;
        position: relative;
        &.rounded {
            border-radius: 50%;
            overflow: hidden;
        }
        .icon{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            border: 1px solid var(--gray500);
            margin-bottom: 0.625rem;
            /*position: absolute;
            top: calc(50% - 1.5rem);
            left: calc(50% - 1.5rem);*/
            z-index: 2;
        }
        .img-thumbnail {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }
    .form-control{
        &[type="file"]{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            border: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 3;
        }
    }
}
.upload-image-wrap {

}
