header{
  //  padding-top: 0.5rem;
   // padding-bottom: 0.5rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    &.header-landing{
        position: sticky;
        top: 0;
        background-color: var(--white);
        z-index: 99;
        padding: 5px 0px;
    }
}
.header-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left{
        display: flex;
        align-items: center;
        width: 12.75rem;
        padding-right: 1rem;
    }
    .header-right{
        display: flex;
        align-items: center;
        width: calc(100% - 12.75rem);
        flex-wrap: wrap;
        justify-content: flex-end;
        float: right;
    }
    .logo_Main{
        height: 80px;
        margin-right: auto;
        padding-left: 10px;
    }
    .admin-logo{
      height: 80px;
      margin-right: auto;
      padding-left: 10px;
  }
    .h-search{
        display: flex;
        width: 65%;
        padding-right: 1.375rem;
        form {
            width: 100%;
        }
    }
    .h-buttons{
        display: flex;
        margin-left: 1.25rem;
        .btn.btn-small{
            font-size: 15px;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-left: 5px;
            text-transform: capitalize;
            font-weight: 500;
            &:first-child{
                margin-left: 0;
            }
        }
        select{
            &.form-control{
                margin-bottom: 0;
                height: 2.25rem;
                border-radius: 5px;
                padding: 0.5rem 0.625rem;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    header{
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }
    .header-main{
        display: flex;
        flex-direction: column;
        .header-left{
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
        .header-right{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .logo{
            margin-right: 0px;
        }
        .h-search{
            width: 100%;
            padding-right: 0px;
            margin-bottom: 0.625rem;
        }
        .h-language{
            margin-bottom: 0.625rem;
        }
        .admin-logo{
          max-height: 70px;
        }
        .h-buttons{
            display: flex;
            flex-direction: column;
            margin: 0;
            .btn.btn-small{
                padding-left: 1rem;
                padding-right: 1rem;
                margin-left: 0px;
                margin-bottom: 1rem;
                text-transform: inherit;
                font-weight: 500;
            }
        }
    }
    .menu-collase {
        position: fixed;
        top: 0;
        width: 80%;
        display: flex;
        flex-direction: column;
        height: 100vh;
        z-index: 99;
        background-color: rgba(255,255,255,0.9);
        padding: 1rem;
        right: -100%;
        transition: right ease-out 0.2s;
        &.menu-open{
            right: 0%;
        }
        // &::before{
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     bottom: 0;
        //     left: 0;
        //     z-index: -1;
        //     backdrop-filter: blur(40px);
        // }
    }
    .menu-close-button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.625rem;
        .btn{
            padding: 0;
        }
    }
}

.me-auto{
    margin-right: auto;
}
.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 30px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
   
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
    list-style-type: none;
    
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    font-weight: 500;
    text-decoration: none;
    color: var(--black);
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #8081ff;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
    
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }

  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #8081ff;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
  .navbar .search-bar {
    min-width: 360px;
    padding: 0 20px;
  }
  
  @media (max-width: 1199px) {
    .navbar .search-bar {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      padding: 20px;
      box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
      background: white;
      z-index: 9999;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }
  
    .navbar .search-bar-show {
      top: 60px;
      visibility: visible;
      opacity: 1;
    }
  }
  
  .navbar .search-form {
    width: 100%;
  }
  
  .navbar .search-form input {
    border: 0;
    font-size: 14px;
    color: #012970;
    border: 2px solid rgba(1, 41, 112, 0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
  }
  
  .navbar .search-form input:focus,
  .navbar .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
  }
  
  .navbar .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
  }
  
  .navbar .search-form button {
    color: #8081ff;
  }
  
  