.side-panel-icon {
  position: relative;
  display: flex;
  align-items: center;

  .side-panel-chevron-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5px;
    transition: all ease-in 0.2s;

    &.active {
      left: 1rem;
    }
  }
}

.side-panel-lists-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 320px;
  position: relative;
  height: 100%;

  .side-panel-lists-heading {
    display: flex;
    flex-direction: column;
    // min-width: 320px;
    min-height: 3.4375rem;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0.625rem;

    &.side-panel-lists-heading-active {
      // margin-left: 320px;
    }

    .side-panel-lists-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .side-panel-lists-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#ddd;
    // margin-left: -320px;
    margin-top: -4.0625rem;
    padding: 1.875rem 1.5rem;
    height: 100%;
    overflow-y: auto;
    &.side-panel-lists-content-active {
      margin-left: 0;
    }

    .spls-title {
    }
  }
}

.spls-nav-card {
  .spls-title {
    & + h3 {
      margin-top: 0;
    }

    span {
      font-size: 1.275rem;
      font-weight: 500;
      color: var(--whitehalf);
      letter-spacing: 0.4px;
    }
    &.inactive {
      padding: 10px 0;
    }

  }

  .spls-catname {
    font-size: 1.15rem;
    text-transform: uppercase;
    color:var(--primary);
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .dropdown-sublists {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;

    .dropdown-sublists-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.125rem;
      font-weight: 500;
      color: #0d6efd;
      background-color: var(--secondary1100);
      border-radius: 5px;
      padding: 0.75rem 1.25rem 0.75rem 0.625rem;
      cursor: pointer;
      text-transform: capitalize;

      &.active {
        color: var(--primary);
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .side-panel-icon {
    > svg{
      width: 3rem;
      height: 2rem;
    }
    .side-panel-chevron-icon {
      svg{
        width: 1.25rem;
        height: 1.125rem;
      }
      &.active {
        left: 0.5rem;
      }
    }
  }
  .side-panel-lists-wrapper {
    .side-panel-lists-heading {
      min-height: 2rem;
    }

    .side-panel-lists-content {
      margin-top: 0;
      padding: 1rem;
    }
  }
  .spls-nav-card {
    .spls-catname {
      font-size: 1.125rem;
    }
    .dropdown-sublists {
      margin-bottom: 0.5rem;
      .dropdown-sublists-title {
        font-size: 1rem;
      }
    }
  }
}
