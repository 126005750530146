.member-tree-wrapper {
    width: 100%;
    height: 400px;
    overflow: auto;
}

.member-tree-user-box{
    display: inline-flex;
    flex-direction: column;
    .member-tree-user-card{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .mtu-title{
            font-weight: 400;
            margin-bottom: 0.5rem;
        }
        .image-element{
            justify-content: center;
            margin-bottom: 0.9375rem;
            img{
                max-width: 4.375rem;
                max-height: 4.375rem;
            }
        }
        .mtu-code{
            background-color: var(--bright);
            font-weight: 600;
            padding: 3px 0.9375rem;
            margin: 0;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 767px) {
    .member-tree-user-box{
        .member-tree-user-card{
            .mtu-title{
                margin-bottom: 5px;
            }
            .image-element{
                justify-content: center;
                margin-bottom: 0.9375rem;
                img{
                    max-width: 3.125rem;
                    max-height: 3.125rem;
                }
            }
            .mtu-code{
                background-color: var(--bright);
                font-weight: 600;
                padding: 3px 0.9375rem;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
}
