.welcomeus{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
    h1{
        margin-bottom: 3.125rem;
    }
    .image-element{
        margin-bottom: 3.125rem;
    }
    h3{
        margin-bottom: 0;
    }
}
@media screen and (max-width: 767px) {
    .welcomeus{
        h1{
            margin-bottom: 1.25rem;
        }
        .image-element{
            margin-bottom: 1.25rem;
            max-width: 75%;
        }
        h3{
            margin-bottom: 0;
        }
    }
}