/*======contact us css======*/
html .bg-color-primary, html .bg-primary {
    background-color: #8081ff !important;
  }
  .border-0 {
    border: 0 !important;
  }
  @media (min-width: 992px){
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  }
  .feature-box.feature-box-style-2 .feature-box-icon {
    background: 0 0;
    top: -3px;
  }
  .feature-box .feature-box-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    border-radius: 100%;
    color: #fff;
    font-size: 1rem;
    position: relative;
    text-align: center;
    padding: .8em;
    width: 2.7em;
    height: 2.7em;
  }
  .feature-box .feature-box-info {
    flex: 1 1 100%;
  }
  html .text-color-light, html .text-light {
    color: #fff !important;
  }
  .font-weight-light {
    font-weight: 300 !important;
  }
  .opacity-7 {
    opacity: .7 !important;
  }
  h5 {
    font-size: 1em;
    line-height: 18px;
    margin: 0 0 14px;
    text-transform: uppercase;
  }
  
  
  /*=======form section start========*/
  .form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 13.6px;
    font-size: .85rem;
    line-height: 1;
  }
  .form-style-2 .form-control:not(textarea) {
    height: calc(2.2em + .75rem + 2px);
  }
  .form-style-2 .form-control {
    padding: .7rem 1rem;
    border: 0;
  }
  .form-style-2 .form-group {
    margin-bottom: .7rem;
  }
  .bg-color-grey-scale-1 {
    background-color: #ededed !important;
  }
  .pricing-table .plan {
    background: #fff;
    border-radius: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .pricing-table .plan .plan-header {
    background: #8081ff;
    padding: .95rem 1rem;
  }
  .pricing-table .plan .plan-price {
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    border-top: 0;
    border-bottom: 0;
    padding: 2rem .8rem;
    text-align: center;
  }

  
    .mapouter{
      position:relative;
      text-align:right;
      width:100%;
      height:400px;
    }
    .gmap_canvas {overflow:'hidden';
      background:none!important;
      width:100%;
      height:400px}
    .gmap_iframe {
      height:400px!important
    }
    .info-item p{
      padding: 0px;
      margin: 0px;
    }
  