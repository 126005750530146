.tooltip{
    background-color: var(--link);
    color: var(--white);
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.5rem 0.625rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    &::after{
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background-color: var(--link);
        position: absolute;
        transform: rotate(45deg);
    }
    &.tooltip-left{
        &::after{
            left: -0.25rem;
            top: calc(50% - 0.25rem);
        }
    }
    &.tooltip-right{
        &::after{
            right: -0.25rem;
            top: calc(50% - 0.25rem);
        }
    }
    &.tooltip-top{
        &::after{
            top: -0.25rem;
            left: calc(50% - 0.25rem);
        }
    }
    &.tooltip-down{
        &::after{
            bottom: -40.25rempx;
            left: calc(50% - 0.25rem);
        }
    }
}