.section-wrapper{
    width: 100%;
    display: flex;
    flex-direction:column;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    &.footer-middle{
        background-color: var(--light500);
        color: var(--black);
    }
    &.section-gallery-follow{
        background-color: var(--white);
        background: linear-gradient(180deg,var(--white) 50%, var(--light400) 50%);
        color: var(--black);
    }
}
.section-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // margin-bottom: 1.875rem;
}
.section-title{
    margin-bottom: 0;
}

.favourite-section{
    background-color: var(--secondary300);
}
@media screen and (max-width: 767px) {
    .section-wrapper{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    }
    .section-head{
        margin-bottom: 0.9375rem;
        .btn{
            white-space: nowrap;
        }
    }
    .section-title{
        font-size: 1.125rem;
        margin-bottom: 0px;
        // display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        &::after{
            right: inherit;
        }
    }
}

@media screen and (max-width: 375px) {
    .section-title{
        font-size: 1rem;
    }
}