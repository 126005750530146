/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-bottom: 10px;
  }
  
  .about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #8081ff;
  }
  
  .about .content .learn-more-btn {
    background: #8081ff;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px 9px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
  }
  
  .about .content .learn-more-btn:hover {
    background: #4e4c4c;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .about .content .learn-more-btn {
      margin: 0 48px 0 0;
      padding: 6px 18px;
    }
  }