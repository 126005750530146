.button-group-wrapper{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    margin-bottom: 1.25rem;
    padding-bottom: 2.25rem;
    .btn-button{
        border-radius: 5px;
        padding: 0.875rem 1.5rem;
        display: flex;
        align-items: center;
        text-align: center;
        background-color: var(--inactive);
        color: var(--white70);
        text-decoration: none;
        margin-right: 0.75rem;
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: 500;
        white-space: nowrap;
        cursor: pointer;
        border: 0px;
        &.active{
            font-weight: 700;
            background-color: var(--primary);
            color: var(--white);
        }
    }
}
.lbt-section{
    .topic-video-card{
        margin-bottom: 4.375rem;
        .topic-video-details{
            .topic-video-details-content{
                .topic-video-details-content-title{
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }
                .topic-video-details-content-by{
                    font-size: 0.875rem;
                }
                .topic-video-details-content-short-desc{
                    font-size: 0.8125rem;
                }
                .topic-video-details-content-views{
                    font-size: 0.875rem;
                }
            }
        }
    } 
}
@media screen and (max-width: 767px) {
    .button-group-wrapper{
        padding-bottom: 0.625rem;
        .btn-button{
            padding: 0.625rem 1rem;
            margin-right: 5px;
            font-size: 1rem;
        }
    }
    .lbt-section{
        .topic-video-card{
            margin-bottom: 1.25rem;
            .topic-video-details{
                margin-top: 0.625rem;
                .topic-video-details-content{
                    .topic-video-details-content-title{
                        font-size: 1.125rem;
                    }
                }
            }
        } 
    }
}

.vpd-upload-info-right{
    .btn{
        font-size: 1.125rem;
        text-transform: inherit;
    }
}