.pageHeaderBack{
    background-image: url(../../assets/img/about.jpg);
     background-size: cover; 
    background-position: center;
}

/*=======page header css start=============*/
.page-header {
    background-color: #212529;
    padding: 30px 0;
    position: relative;
    text-align: left;
  }
  
  .page-header h1 {
    color: #fff;
    display: inline-block;
    font-size: 30px;
    line-height: 1;
    margin: 0;
    padding: 0;
    font-weight: 400;
    position: relative;
    top: 1px
  }
  
  .page-header.page-header-modern h1 {
    font-size: 28px;
    letter-spacing: 0.8px
  }
  
  .page-header.page-header-modern.page-header-background {
    padding: 130px 0;
    background-size: cover
  }
  
  /* .page-header.page-header-modern.page-header-background.page-header-background-sm {
    padding: 50px 0
  } */

  
  