.no-video-uploaded-yet-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 400px;
    h3{
        margin-bottom: 1.875rem;
    }
    p{
        color: var(--whitehalf);
        margin: 0 0 3.125rem;
    }
}