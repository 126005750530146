header.header-guru{
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 6.1125rem;
    background-color: var(--white);
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    z-index: 9999;
    > .container-fluid{
        padding-left: 0;
        height: 100%;
    }
    .header-main{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        .header-left{
            display: flex;
            align-items: center;
            width: 245px;
            height: 100%;
            padding-left: 0.9375rem;
        }
        .header-right{
            display: flex;
            align-items: center;
            width: calc(100% - 245px);
            flex-wrap: wrap;
            justify-content: flex-end;
            height: 100%;
        }
        .logo{
            margin-right: 0px;
        }
        .h-search{
            display: flex;
            width: 65%;
            padding-right: 1.375rem;
        }
        .h-dp{
            display: flex;
            align-items: center;
            height: 100%;
        }
        .beaguru-link{
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.375rem;
            color: var(--inactive);
            &.btn-active {
                color: var(--white);
            }
        }
        .dp-wrap{
            display: flex;
            align-items: center;
            margin-left: 1.25rem;
            .dp-details{
                margin-left: 0.75rem;
                display: flex;
                flex-direction: column;
                h4{
                    font-size: 0.8125rem;
                    font-weight: 600;
                    margin-bottom: 0;
                }
                P{
                    font-size: 0.6875rem;
                    margin: 0;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
    header.header-guru{
        .header-main{
            .h-search{
                width: 50%;
            }
        }
    }
}
@media screen and (max-device-width: 640px){
.sidebar{display: none;}
}
@media screen and (max-width: 767px) {
    header.header-guru{
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        
        .header-main{
            display: flex;
            flex-direction: column;
            .header-left{
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
            .header-right{
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            .logo{
                margin-right: 0px;
            }
            .h-search{
                width: 100%;
                padding-right: 0px;
                margin-bottom: 1rem;
            }
            .h-language{
                margin-bottom: 0.625rem;
            }
            .h-buttons{
                display: flex;
                flex-direction: column;
                .btn.btn-small{
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-left: 0px;
                    margin-bottom: 1rem;
                    text-transform: inherit;
                    font-weight: 500;
                }
            }
            .h-dp{
                flex-direction: row-reverse;
                width: 100%;
                justify-content: space-between;
                height: auto;
                margin-bottom: 1.5rem;
                align-items: center;
                .dp-wrapper{
                    padding-left: 0;
                    padding-right: 0.625rem;
                }
            }
        }
        .menu-collase {
            position: fixed;
            top: 0;
            width: 90%;
            display: flex;
            flex-direction: column;
            height: 100vh;
            z-index: 99;
            background-color: var(--white);
            color: var(--black);
            padding: 1rem;
            right: -100%;
            transition: right ease-out 0.2s;
            &.menu-open{
                right: 0%;
            }
        }
        .menu-close-button {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0.625rem;
            .btn.btn-link{
                color: inherit;
                padding: 0;
            }
        }
    }
}
