.btn{
    font-family: var(--defaultfontfamily);
    padding: 0.625rem 1rem;
    border-radius: 50px;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &.btn-large{
        font-size: 14px;
        padding: 0.5rem 1rem;
        border-radius: 8px;
    }
    &.btn-small{
        font-size: 1.125rem;
        padding: 6px 15px;
        border-radius: 50px;
       position: relative;
       left: -50px;
    }

    &.btn-small-out{
        font-size: 1.125rem;
        padding: 6px 15px;
        border-radius: 50px;
       position: relative;
       
    }
    &.btn-primary{
        background-color: var(--primary);
        border-color: var(--primary);
        color: var(--white);
        &.btn-outline{
            background-color: transparent;
            color: var(--primary);
        }
        &:hover{
            background-color: #666666;
            color: var(--white);
        }
    }
    &.btn-primary-dashboard{
        background-color: var(--primary);
        border-color: var(--white);
        color: var(--white);
        
        border:1px solid #fff;
        &.btn-outline{
            background-color: transparent;
            color: var(--primary);
            border: #fff;
        }
        &:hover{
            background-color: #666666;
            color: var(--white);
            border: #fff;
        }
        &:active{
            background-color: #FFD109;
            color: var(--white);
        }
    }

    
    &.btn-gray{
        background-color: var(--primary);
        border-color:var(--primary);
        color: var(--white);
        &:hover{
            background-color: #FFD109;
            color: var(--white);
        }
        &:active{
            background-color: #FFD109;
            color: var(--white);
        }
        &.btn-outline{
            background-color: transparent;
            border-color: #FFD109;
            color: var(--white);
        }
    }
    &.btn-success{
        background-color: var(--green);
        border-color: var(--green);
        color: var(--white);
        &.btn-outline{
            background-color: transparent;
            color: var(--green);
        }
    }
    &.btn-danger{
        background-color: red;
        border-color: red;
        color: var(--white);
        &.btn-outline{
            background-color: transparent;
            color: red;
        }
    }
    &.btn-dark{
        background-color: transparent;
        border-color: var(--black);
        color: var(--black);
        &:hover{
            background-color: var(--black);
            color: var(--white);
        }
        &.btn-outline{
            background-color: transparent;
            color: var(--black);
        }
    }
    &.btn-light{
        padding: 8px 10px;
        // margin-bottom: 20px;
       background-color: var(--white);
        color: var(--green600);
        border-radius: 8px;
        // font-size: 18px;
        font-weight: 500;
        &:hover{
            background-color: var(--white);
            color: var(--black);
        }
        &.btn-outline{
            background-color: transparent;
            color: var(--black);
            border-color: var(--black);
        }
    }
    &.btn-disabled{
        background-color: var(--gray);
        border-color: var(--gray);
        color: var(--gray100);
        &.btn-outline{
            background-color: transparent;
            color: var(--gray);
        }
    }
    &.btn-active{
        background-color: var(--primary);
        border-color: var(--primary);
        color: var(--white);
    }
    &.btn-inactive{
        background-color: var(--inactive);
        border-color: var(--inactive);
        color: var(--white);
    }
    &.btn-link{
        padding: 0;
        background-color: transparent;
        color: var(--black);
        border: 0;
    }

        
        .btn-link svg{
            width: 20px;
            
        }
    &.btn-link-light{
        padding: 0;
        background-color: transparent;
        color: var(--white);
        border: 0;
        
    }
    &.btn-link-little{
        padding: 0;
        background-color: transparent;
        color: var(--black);
        border: 0;
        font-size: 1.125rem;
        font-weight: 500;
        text-transform: capitalize;
        display: inline-flex;
    }

    
    &.btn-circle-primary{
        padding: 0;
        background-color: transparent;
        color: var(--primary);
        border: 1px solid var(--primary);
        text-transform: inherit;
        font-size: 1.125rem;
        font-weight: 500;
        text-decoration: underline;
        display: inline-flex;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        .btn-icon{
            margin-left: 0;
        }
        &:hover{
            background-color: var(--primary);
            border-color: var(--primary);
            color: var(--white);
        }
        + .btn{
            margin-left: 1rem;
        }
    }
    &.btn-link-primary{
        padding: 0;
        background-color: transparent;
        color: var(--primary);
        border: 0;
        text-transform: inherit;
        font-size: 1.125rem;
        font-weight: 500;
        text-decoration: underline;
        display: inline-flex;
        &.btn-small{
            padding: 0;
            font-size: 0.875rem;
            .btn-icon{
                svg{
                    width: 6px;
                    height: 8px;
                }
            }
        }
        
    }
    
    &.btn-link-secondary{
        padding: 0;
        background-color: transparent;
        color: #8081ff;
        border: 0;
        text-transform: inherit;
        font-size: 1rem;
        font-weight: 400;
        text-decoration: underline;
        display: inline-flex;
    }

    &.btn-link-terms-secondary{
        padding: 0;
        background-color: transparent;
        color: #8081ff;
        border: 0;
        text-transform: inherit;
        font-size: 0.9rem;
       
        text-decoration: underline;
        display: inline-flex;
    }
    &.btn-link-danger{
        padding: 0;
        background-color: transparent;
        color: #0d6efd;
        border: 0;
        text-transform: inherit;
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        display: inline-flex;
    }
    
    .btn-icon{
        margin-left: 1rem;
        display: flex;
    }
    .btn-icon-left{
        margin-left: 1rem;
        display: flex;
    }
    + .btn{
        margin-left: 2rem;
    }
    .loading {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.625rem;
        animation: spin 2s linear infinite;
        svg {
            path {
                fill: var(--primary100  );
            }
        }
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@media screen and (max-width: 767px) {
    .btn{
        padding: 0.625rem 0.75rem;
        font-weight: 700;
        font-size: 1rem;
        &.btn-large{
            font-size: 1.25rem;
            padding: 1rem 1.5rem;
        }
        &.btn-small{
            font-size: 0.75rem;
            padding: 0.5rem 0.75rem;
            line-height: 1.125rem;
        }
        &.btn-link-little{
            font-size: 1rem;
            font-weight: 500;
        }
        &.btn-circle-primary{
            font-size: 1rem;
            font-weight: 500;
            width: 2.5rem;
            height: 2.5rem;
            .btn-icon{
                margin-left: 0;
            }
            + .btn{
                margin-left: 0.5rem;
            }
        }
        &.btn-link-primary{
            font-size: 1rem;
            font-weight: 500;
            &.btn-small{
                padding: 0;
                font-size: 0.875rem;
                .btn-icon{
                    svg{
                        width: 6px;
                        height: 8px;
                    }
                }
            }
        }
        &.btn-link-secondary{
            font-size: 1rem;
            font-weight: 700;
        }
        &.btn-link-danger{
            font-size: 1rem;
            font-weight: 700;
        }
        .btn-icon{
            margin-left: 0.5rem;
            display: flex;
        }
        .btn-icon-left{
            margin-right: 1.5rem;
            display: flex;
        }
        + .btn{
            margin-left: 0.5rem;
        }
        .loading {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
        }
    }       
}

.btn-verification{
    padding: 6px 20px !important;
    max-height:50px ;
    background-color: #8081ff !important;
    color: #fff !important;
    border-radius: 8px;
}

.btn-danger{
    background-color: red;
    color: #fff;
}

.btn-Req-Cat{
    font-size: 13px;
    /* float: right; */
    color: blue;
    cursor: pointer;
    text-align: center;
}