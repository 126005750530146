/*--course card css start--*/

.custom-card-courses {
    border:0!important
   }
   .custom-card-courses .custom-card-courses-author {
    position:absolute;
    bottom:-30px;
    right:20px
   }
   .custom-card-courses .custom-card-courses-author img {
    height:60px;
    width:60px;
    border-radius:50%!important
   }
   
   .border-radius-0 {
    border-radius:0!important
   }

   .hover-effect-1:not(.portfolio-item) {
    box-shadow: 10px 10px 74px -15px transparent;
    transition: ease transform 300ms, ease box-shadow 300ms
}

.hover-effect-1:not(.portfolio-item):hover {
    box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, .4);
    transform: translate3d(0, -15px, 0)
}

.img-thumbnail {
    border-radius: 4px;
    width: 150px;
    height: 150px;
    position: relative
}

.img-thumbnail img {
    border-radius: 4px
}

.img-thumbnail .zoom {
    background: #ccc;
    border-radius: 100%;
    bottom: 8px;
    color: #fff;
    display: block;
    height: 30px;
    padding: 6px;
    position: absolute;
    right: 8px;
    text-align: center;
    width: 30px
}

.img-thumbnail .zoom i {
    font-size: 10px;
    left: 0;
    position: relative;
    top: -6px
}

.img-thumbnail.img-thumbnail-hover-icon {
    position: relative
}

.img-thumbnail.img-thumbnail-hover-icon img {
    position: relative;
    z-index: 1
}

.img-thumbnail.img-thumbnail-hover-icon:before {
    transition: all .2s;
    background: rgba(0, 0, 0, .65);
    border-radius: 4px;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 2
}

.img-thumbnail.img-thumbnail-hover-icon:after {
    transition: all .2s;
    font-family: 'font awesome 6 free';
    font-weight: 900;
    color: #fff;
    content: "\f067";
    font-size: 18px;
    height: 30px;
    position: absolute;
    top: 30%;
    margin-top: -15px;
    display: block;
    text-align: center;
    width: 100%;
    opacity: 0;
    z-index: 3
}

.img-thumbnail.img-thumbnail-hover-icon:hover:before {
    opacity: 1
}

.img-thumbnail.img-thumbnail-hover-icon:hover:after {
    top: 50%;
    opacity: 1
}

.img-thumbnail a.img-thumbnail-hover-icon {
    display: inline-block
}

.img-thumbnail.img-thumbnail-no-borders {
    border: none;
    padding: 0
}

.text-1 {
    font-size:.8em!important
   }
   .p-relative{
    position: relative;
   }

   .top-5 {
    top:5px!important
   }
   html .text-color-secondary,
html .text-secondary {
    color: #e36159 !important;
}
html .text-color-primary,
html .text-primary {
    color: #8081ff !important
}

.trending_bg{
    background-color: #f9f9f9c4;
    padding: 20px 30px;
}