
.draggable {
    display: flex;
   /* align-items: center;
    gap: 1rem;
    margin: 1rem;*/
  }
  
  .draggable-category {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem;
  }
  
  h2 {
    margin: 0;
  }
  
  .drag-handle {
    border: 2px solid blue;
    background: cornflowerblue;
    padding: 1rem;
    border-radius: 4px;
  }
  
  .category-container {
    /*width: 90%;*/
    background-color:transparent !important;
  }
  [data-rbd-drag-handle-context-id="0"] {
    cursor: -webkit-grab;
    cursor: grab;
    margin-left: -10px;
    width: 45px;
  }
  .item {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 1rem;
    width: 111%;
    background: lightgray;
  }
  
  .accordion-button{
    width: 112% !important;
    padding:0px !important;
    margin:0px !important;
  }

  .accordion-collapse{
    width: 112% !important;
    margin-top: -17px;
    background-color: transparent !important;
  }

  .card-body{
    width: 111% !important;
  }

  .accordion-header{
    margin-top:-12px;
  }

  /*.accordion-even-color{
    background:var(--light700) !important;
    background-color:var(--light700) !important;
  }*/
  .accordion-odd-color{
    background: var(--gray100) !important;
    background-color:var(--bright) !important;
    
  }
  