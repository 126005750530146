:root{
    --defaultfontfamily: 'Montserrat', sans-serif;
    --primary: #8081ff;
    --primary100: #FFF8E6;
    --primary200: #FFC943;
    --primary300: #FFEBC9;
    --primary400: #FFF5E3;
    --primary500: #dadaff;
    --secondary: #0B2520;
    --secondary100: #0B2B25;
    --secondary200: #0B2C25;
    --secondary300: #154139;
    --secondary400: #0D2B25;
    --secondary500: #9cc3fd;
    --secondary600: #0B3029;
    --secondary700: #133630;
    --secondary800: #123B33;
    --secondary900: #185247;
    --secondary1000: #0C322B;
    --secondary1100: #113B33;
    --secondary1200: #1F4F44;
    --secondary1300: #10322C;
    --secondary1400: #10352E;
    --secondary1500: #0D2F28;
    --linehash: #666;
    --white: #ffffff;
    --whitehalf: rgba(84, 82, 82, 0.5);
    --blackhalf: rgba(0, 0, 0, 0.5);
    --light800half: rgba(208, 207, 207, 0.5);
    --white70: rgba(255, 255, 255, 0.7);
    --black70: rgba(0, 0, 0, 0.7);
    --black: #000000;
    --bright: #8081ff;
    --gray: #808080;
    --gray100: #C2BFBF;
    --gray200: #B6B6B6;
    --gray300: #797979;
    --grayhalf: rgba(217, 217, 217, 0.5);
    --gray400: #949292;
    --gray500: #ACACAC;
    --gray600: rgb(202, 200, 200);
    --gray700: #A5A5A5;
    --gray800: #B5B5B5;
    --gray900: #eae7e7;
    --gray1000: #B1B1B1;
    --gray1100: #757575;
    --gray8a8a8a: #8A8A8A;
    --light: #D9D9D9;
    --light100: #E4E4E4;
    --light200: #F3F3F3;
    --light300: #D7D7D7;
    --light400: #EBF1F0;
    --light500: #FAFAFA;
    --light600: #F5F5F5;
    --light700: #DADADA;
    --light800: #D0CFCF;
    --overlay: rgba(7, 30, 26, 0.8);
    --link: #144126;
    --inactive: #8081ff;
    --navinactive: #000;
    --black100: #040404;
    --black200: #393939;
    --black300: #343434;
    --black400: #424242;
    --black500: #373737;
    --black600: #4E4D4D;
    --black700: #443F3F;
    --black800: #1E2B28;
    --success: #55CA44;
    --success100: #4DC14B;
    --green: #17652C;
    --green100: #246A5A;
    --green200: #13723E;
    --green300: #F0F9E8;
    --green400: #EDFFEA;
    --green500: #1EA907;
    --green600: #8081ff;
    --green700: #0C6133;
    --green-cyan100: #4DA18E;
    --green-cyan200: #2A7867;
    --green-cyan300: #CAE5DF;
    --green-cyan400: #50C4AA;
    --dagner: #EE3024;
    --danger100: #EF5439;
    --glassblue: #0E8BFF;
    --approved: #37862A;
    --pending: #A9A93D;
    --draft: #4870AC;
    --reject: #AC484E;
    --product:#988583;
    --yellow:#FFD109;
    --orange:#ffa600;
    --switchActiveColor:#84FF95;
    --homeBgColor:#8081ff12;
    --homeCardWhite:#ffffff;


}
