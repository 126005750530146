.hiddenInput {
  .form-field {
    margin-bottom: 0;

    .message-error {
      position: relative;
      // top: -1.875rem;
    }
  }
}

.form-field {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;

  &.form-field-select {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 0.5625rem;
      height: 0.5625rem;
      right: 1.125rem;
      top: calc(50% - 5px);
      border-bottom: 3px solid var(--gray400);
      border-right: 3px solid var(--gray400);
      transform: rotate(45deg);
    }

    &.no-label {
      &::after {
        top: 3.75rem;
      }
    }

    select {
      &.form-control {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-width: 2px;
        position: relative;
      }
    }

    &.form-field-tertiary {
      &::after {
        border-bottom: 3px solid var(--white);
        border-right: 3px solid var(--white);
        top: 2.5rem;
      }

      .form-control {
        background-color: var(--primary);
        color: var(--white);
        font-size: 1rem;
        
        option {
          color: var(--black);
        }
      }
    }
    &.form-field-line-bottom{
      .form-control {
        border-width: 0 0 2px 0;
        color: var(--black);
      }
    }
  }

  .form-control {
    font-family: var(--defaultfontfamily);
    background-color: var(--white);
    border-width: .5px;
    border-style: solid;
    border-color: rgba(172, 172, 172, 0.5);
    outline: none;
    width: 100%;
    font-size: .8rem;
    border-radius: 5px;
    // color: var(--gray400);
    color: var(--black);
    padding: 0.6rem 0.720rem; 

    &.form-control-value {
      border-color: var(--black500);
    }

    &:focus {
      // outline: none;
      border: 1px solid #8081ff !important;
      box-shadow: none;
    }

    &.error {
      border-color: #FF0000;
    }

    &:disabled {
      border-color: var(--gray500);
      background-color: var(--light600);
    }
  }

  .PhoneInput {
    &.error {
      .PhoneInputInput {
        border-color: #FF0000;
      }
    }
  }

  .PhoneInputInput {
    @extend .form-control
  }

  .PhoneInputCountry {
    @extend .form-control;
    width: auto;
  }

  textarea {
    &.form-control {
      height: auto;
      font-family: inherit;
      border-width: 2px;
    border-style: solid;
    border-color: rgba(172, 172, 172, 0.5);
    outline: none;
    width: 100%;
    font-size: 1.125rem;
    border-radius: 5px;
    // color: var(--gray400);
    color: var(--black);
    padding: 0.570rem 0.720rem; 

    }

  }

  .form-label {
    // font-size: 0.9rem;up
    font-weight: 400;
    // color: #666666;
    // margin-bottom: 0.25rem;

  }
  .form-select-container{
    .form-select-styled__control{
      background-color: var(--white);
      border: 2px solid var(--white);
      box-shadow: none;
      min-height: 3rem;
      border-radius: 5px;
      color: var(--black);
      &.form-select-styled__control--is-focused{
        border-color: var(--white);
      }
      .form-select-styled__value-container{
        padding: 0 0 0 1rem;
        .form-select-styled__placeholder{
            margin-left: 0;
            margin-right: 0;
        }
        .form-select-styled__input-container{
            padding: 0;
            margin: 0;
        }
      }
      .form-select-styled__indicator-separator{
          display: none;
      }
    }
    .form-select-styled__menu-list{
      margin: 0;
      padding: 0;
    }
    .form-select-styled__option{
      background-color: var(--white);
      color: var(--black);
      cursor: pointer;
      &.form-select-styled__option--is-focused{
          background-color: var(--primary);
          color: var(--white);
      }
      &.form-select-styled__option--is-selected{
          background-color: var(--primary);
          color: var(--white);
      }
    }
  }

  &.form-field-secondary {
    .form-control {
      background-color: var(--white);
    }
  }

  &.form-field-tertiary {
    .form-label {
      color: var(--black);
      font-size: 1rem;
    }

    .form-control {
      // background-color: var(--white);
      // color: var(--black);
      // font-size: 1rem;
      // height: 3rem;
      // padding: 0.625rem 0.875rem;
      // border: none;
      // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      background-color: var(--white);
      border-width: 0.5px;
      border-style: solid;
      border-color: rgba(172, 172, 172, 0.5);
      outline: none;
      width: 100%;
      font-size: 0.8rem;
      border-radius: 5px;
      color: var(--black);
      padding: 0.57rem 0.72rem;

      &.form-control-value {
        border-color: var(--gray500);
      }
    }
    .form-icon {
      top: 3.125rem;
    }
    textarea {
      &.form-control {
        height: auto;
      }

    }
  }
  &.form-field-line-bottom{
    .form-control {
      border-width: 0 0 2px 0;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: inherit !important;
}

.message-error {
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: #FF0000;
  margin-top: 0.2rem;
}

.text-danger {
  color: #FF0000;
}



.form-icon {
  position: absolute;
  right: 1.625rem;
  top: 1.725rem;
  // top: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-icon svg{ 
  font-size: 20px;
}

.form-icon-1 {
  position: absolute;
  right: 1.625rem;
  top: 15.525rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-icon-2 {
  position: absolute;
  right: 1.825rem;
  top: 34.425rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-icon-3 {
  position: absolute;
  right: 1.825rem;
  top: 39.805rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-icon-4 {
  position: absolute;
  right: 1.625rem;
  top: 2.525rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pswd-icon{
  position: absolute;
  top: 40px;
  right: 1.825rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pswd-icon-1{
  position: absolute;
  top: 40px;
  right: 1.825rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pswd-icon-2{
  position: absolute;
  top: 40px;
  right: 1.825rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.select-box-align{
  max-width: 250px;
  position: relative;
  top: -100px;
  float: right;
}
.select-box-align_1{
  min-width: 230px;
  float: right;
  position: relative;
  top: -16px;
}

.select-new-text-lable{
  margin-left: 5;

  &:hover{
    
    color: var(--black);
  }
  &:active{
    
    color: var(--white);
  }
    
  
}
