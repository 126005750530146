

.home-main-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    color: var(--white);
}
img svg{
    width:100%  !important;
   }
.text-white-color{
    color: var(--white);
}

.text-black-color{
    color: var(--black) !important;
}

.text-hash-color{
    color: var(--blackhalf) !important;
}

.text-theme-color{
    color: var(--primary) !important;
}
.p-0{Padding: 0px !important;}
.marginless-5{margin-top: -5px;}
.module-button-title-txt{

    margin-bottom: 5px;
    margin-left: 5px;
    color: var(--black) !important;
    font-size: 0.7rem;
    text-decoration: none !important;
    text-transform:none;
    
    
}

.switch-innertext{
padding-top: 8px;
font-size: 0.7rem;

font-weight: 400;
color: var(--black);
}


.module-button-title-txt:visited{
    text-decoration: none;
}

.module-button-title-txt:active{
    text-decoration: none;
}
.pop-up-header{
    // background-color:var(--primary) !important; 
    height: 80px;
    margin-left: 0px;
    margin-right: 0px;
}

.course-status-box{
    border: 1px solid var(--white);
    border-radius: 10px;
    // margin-top: 30px;
    // margin-right: 5px;

}

.course-status-box-primary{
    border: 1px solid var(--primary);
    border-radius: 10px;
    // margin-top: 30px;
    // margin-right: 5px;

}

input-share-height {
    width: 90%;
    margin-left: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    transition: 0.5s;
    margin-top: -3px;
    margin-bottom: -2px;
    padding: 5px;
    min-height: 26px;
    max-height: 60px;
  }
  .input-share-height {
    width: 100%;
    border: 1px solid #cecece;
    padding: 5px 10px;
    // height: 40px;
   // display: flow-root list-item;
  }
.category-heading-txt{
    color:var(--black);
    font-size: 0.7rem;
    text-align: center;
    text-justify: center;
    font-weight: 700;
    margin-bottom: 0;
    text-align:center !important;
    text-justify: center !important;
    margin-top:10px;
 
}

.fs-7{
    font-size: 14px;
}
.fs-8{
    font-size: 12px;
}

.w-10{
    width: 10% !important;
}
.p-l-5{
    padding-left: 5px;
}


.p-l-35{
    padding-left: 35px;
}

.p-r-40{
    padding-right: 40px;
}


.p-r-5{
    padding-right: 5px;
}
.p-t-5{
    padding-top: 15px !important;
}
.dashboard-share-txt{
    color: #000000;
    font-size: 14px;
    margin-bottom: 0px;
   }
.dashboard-share-icon{
   height: 20px;
   width: 20px;
 margin-bottom:10px;
   margin-left: 5%;
   display: inline !important;
}
.text-theme-color {
    color: var(--primary) !important;
    display: block ruby;
    // padding: 6px 10px 0 0px !important;
    line-height: 30px;
    margin: 0px !important;
  }

  .text-color-white {
    color: var(--white) !important;
    display: block ruby;
    // padding: 6px 10px 0 0px !important;
    line-height: 30px;
    margin: 0px !important;
  }
  .dash-icon-100 {
    height: 100px;
    width: 135px;
    display: block;
    padding: 0 0;
    margin: 20px 0;
    float: left;
}
.dash-text {
    /* text-align: left; */
    font-weight: bold;
    float: left;
    line-height: 135px;
    padding-left: 25px;
}

.dash-icon-80 {
    height: 100px;
    width: 135px;
    display: block;
    padding: 0 0;

    float: left;
}

.dash-icon-50 {
    height: 70px;
    width: 70px;
    display: block;
    padding: 0 0;

    float: left;
}

.dash-text-dashboard {
    text-align: left;
    font-weight: bold;
    float: left;
    line-height: 80px;
    padding-left: 25px;
  }
.icon-32{
    height:20px !important;
    width: 20px !important;
    margin-bottom:5px;
    margin-left:5px;
 }

 .icon-50{
    height:50px;
    width: 50px;
    margin-bottom:5px;
    margin-left:5px;
 }

 .icon-175{
    height:175px;
    width: 175px;
    margin-bottom:5px;
    margin-left:5px;
 }

 .icon-25{
    height:25px;
    width: 25px;
    margin-bottom:5px;
    margin-left:5px;
 }
.course-main-box{
    margin-left: 0px;
    padding:0px;
    margin-right:0px;
    margin-top:20px;
    
}

.width_100{
width: 100% !important;
}

.width_101{
    width: 101% !important;
}

.width_111{
    width: 111% !important;
}

.select-placeholder-text {
    color: rgb(255, 255, 255);
}

.course-select{
 height: 50px;
 line-height: 50px;
 font-size: 12pt;
}


.all-main-box{
    margin-left: 0px;
    padding:0px;
    margin-right:0px;
 
}

.float-right{
    float:right !important;
}

.pading-left-right-15{
    padding-left:15px;
    padding-right:15px;
    
}

.popup-padding{
    padding: 0.6875rem;
}

.section-tent{
    overflow: hidden;
    position: relative;
    .tent-slider{
        width: 100%;
        max-width: 80%;
        .swiper{
            overflow: inherit;
            position: inherit;
        }
        .video-caption{
            .video-title{
                font-size: 1.25rem;
                font-weight: 700;
            }
            .video-desc{
                font-size: 0.875rem;
                max-width: 60%;
            }
        }
    }
}
.overflow-outer{
    overflow: hidden;
    .slider-main{
        .swiper{
            overflow: inherit;
        }
        &.what-us-offers-slider{
            .swiper{
                .swiper-slide{
                    height: auto;
                }
            }
        }
    }
}
.rml-24{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: calc(100% + 3rem);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.max-slider{
    max-width: 85%;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-uppercase{
    text-transform: uppercase;
}
.text-nocase{
    text-transform:none;
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.font-size-20{
    font-size: 1.25rem;
}
.font-size-2rem{
    font-size: 2rem;
}

.font-48{
    font-size: 3rem;
    line-height: 1.2;
}
.disclaimer-text{
    color: var(--whitehalf);
    font-size: 1rem;
}
.padding-10{
    padding: 10px;
}
.margin-10{
    margin: 10px;
}

.viewcourse-btn{
    background-color: var(--white) !important;
}

.li-module-list{
    
    list-style-image: url('../img/sortModule.png');
}
.p-t-10{padding-top: 10px;}
.bg-gray{
    background-color: var(--gray900);
}
.module-list-titlebox{
    background-color: var(--primary);
    padding: 5px;
    border-radius: 5px;
}

.star{
    /*background: url('../img/logo.svg') no-repeat;*/
 
}

.table-quiz-question-colm{
    line-height: 1.2;width:40%
}
.extd{
    margin-left: 0px;
    padding:0px;
    margin-right:0px;
    margin-top:20px;
}
.top-main-boxes{
   width: 100%;
    background-color: var(--white) !important;
    border-radius: 5px;
    align-items: center;
    // padding: 0px 5px 0px 0px !important;
}

.top-main-boxes-primary{
    width: 100%;
     background-color: var(--primary) !important;
     border-radius: 5px;
     align-items: center;
     // padding: 0px 5px 0px 0px !important;
 }

.top-main-transparent-boxes{
    /* width: 100%;*/
     background-color: transparent !important;
     border-radius: 5px;
     padding: 0px 5px 0px 0px !important;
 }
.top-purple-boxes{
    width: 100%;
    background-color: var(--bright) !important;
    border-radius: 5px;
}

.small-sub-text{
    color: var(--whitehalf);
    font-size: 0.8rem;
}

.small-sub-blacl-text{
    color: var(--black);
    font-size: 0.8rem;
}

.term-condition-text{
    color: var(--black);
    font-size: 0.8rem;
    margin-top: 10px;
    margin-left: 30px;
}

.term-condition-bold-text{
    color: var(--black);
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 30px;
}

.term-condition-extra-bold-text{
    color: var(--black);
    font-size: 1rem;
}

.section-readyus{
    background-color: var(--secondary1400);
    background-image: url('../img/logo.svg');
    background-position: top left;
    background-repeat: no-repeat;
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    z-index: 2;
    overflow: hidden;
    &::after{
        content: '';
        background-image: url('../img/logo.svg');
        background-position: right bottom;
        background-repeat: no-repeat;
        opacity: 0.3;
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        left: 0;
        z-index: -1;
    }
}
.row.row-mrl-0{
    margin-left: 0px;
    margin-right: 0px;
    > *{
        padding-left: 0px;
        padding-right: 0px;
    }
}
.row.row-mrl-4{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    > *{
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}
.row.row-mrl-5{
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;
    > *{
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;
    }
}
.row.row-mrl-6{
    margin-left: -0.375rem;
    margin-right: -0.375rem;
    > *{
        padding-left: 0.375rem;
        padding-right: 0.375rem;
    }
}
.row.row-mrl-8{
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    > *{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
.font-weight-400{
    font-weight: 400;
}
.font-weight-500{
    font-weight: 500;
}
.font-weight-600{
    font-weight: 600;
}
.font-weight-700{
    font-weight: 700;
}
.p-20{padding: 20px;;}

.mr-10{
    margin-right: 0.625rem !important;
}
.mr-24{
    margin-right: 1.5rem !important;
}
.mr-30{
    margin-right: 1.875rem !important;
}
.mr-40{
    margin-right: 2.5rem !important;
}
.mr-48{
    margin-right: 3rem !important;
}
.mr-64{
    margin-right: 4rem !important;
}
.mr-80{
    margin-right: 5rem !important;
}
.m-10{margin: 10px;}
.p-10{padding: 10px;}
.ml-10{
    margin-left: 0.625rem !important;
}
.ml-24{
    margin-left: 1.5rem !important;
}
.ml-30{
    margin-left: 1.875rem !important;
}
.ml-40{
    margin-left: 2.5rem !important;
}
.ml-48{
    margin-left: 3rem !important;
}
.ml-64{
    margin-left: 4rem !important;
}
.ml-80{
    margin-left: 5rem !important;
}
.mb-10{
    margin-bottom: 0.625rem !important;
}
.mb-20{
    margin-bottom: 1.25rem !important;
}
.mb-24{
    margin-bottom: 1.5rem !important;
}
.mb-30{
    margin-bottom: 1.875rem !important;
}
.mb-40{
    margin-bottom: 2.5rem !important;
}
.mb-48{
    margin-bottom: 3rem !important;
}
.mb-64{
    margin-bottom: 4rem !important;
}
.mb-80{
    margin-bottom: 5rem !important;
}
.mt-10{
    margin-top: 0.625rem !important;
}
.mt-20{
    margin-top: 1.25rem !important;
}
.mt-24{
    margin-top: 1.5rem !important;
}
.mt--24{
    margin-top: -1.5rem !important;
}
.mt-30{
    margin-top: 1.875rem !important;
}
.mt-32{
    margin-top: 2.155rem !important;
}
.mt-40{
    margin-top: 2.5rem !important;
}
.mt-48{
    margin-top: 3rem !important;
}
.mt-64{
    margin-top: 4rem !important;
}
.mt-80{
    margin-top: 5rem !important;
}

.text-transform-none{
    text-transform: none !important;
}

.border-left-1-32{
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 2rem;
    margin-left: 2rem;
}
.text-right{
    text-align: right;
}
.text-primary{
    color: var(--primary);
}
.text-success{
    color: var(--success);
}
.text-black{
    color: var(--black);
}
.text-black-half{
    color: var(--blackhalf);
}
.text-white{
    color: var(--white);
}
.text-white-half{
    color: var(--whitehalf);
}
.text-gray{
    color: var(--gray900);
}

.module-title-text{
    color: var(--white);
    font-weight: bold;
    
    
}

.text-gray8a8a8a{
    color: var(--gray8a8a8a);
}
.line-height-1{
    line-height: 1;
}
.or{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    &::before{
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--black);
        position: absolute;
        top: calc(50% - 2px);
    }
    span{
        display: flex;
        background-color: #fff;
        padding-left: 3.4375rem;
        padding-right: 3.4375rem;
        position: relative;
    }
}
.connect-social{
    margin-bottom: 1rem;
    .btn{
        margin-left: 2.25rem;
        &:first-child{
            margin-left: 0;
        }
    }
}
.eyeicon-field{
    position: absolute;
    display: flex;
    align-items: center;
}
.swiper{
    .swiper-button-next,
    .swiper-button-prev{
        color: var(--primary);
        top: calc(50% - 3.125rem);
        background-color: var(--white);
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        &::after{
            font-size: 2rem;
        }
        &.swiper-button-disabled{
            opacity: 0;
        }
    }
}
.watch-next-slider{
    .swiper{
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        .swiper-button-next,
        .swiper-button-prev{
            width: 1.5rem;
            height: 1.5rem;
            background-color: var(--primary);
            color: var(--white);
            top: calc(50% - 0.75rem);
            &::after{
                font-size: 0.5rem;
            }
        }
        .swiper-button-next{
            right: 0;
        }
        .swiper-button-prev{
            left: 0;
        }
    }
}
.swiper-pagination{
    &.swiper-pagination-bullets{
        &.swiper-pagination-horizontal{
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .swiper-pagination-bullet{
        width: 0.625rem;
        height: 0.625rem;
        background-color: var(--primary);
        border-radius: 50%;
        margin: 0 2px;
    }
}
.otp-wrapper{
    input{
        display: flex;
        width: 3.75rem !important;
        height: 3.75rem !important;
        margin: 0 1.25rem;
        border: 1px solid var(--primary);
        background-color: var(--primary300);
        border-radius: 50%;
        font-weight: 700;
        font-size: 1.75rem;
        color: var(--black);
    }
}
.subscribe-textimg{
    background-color: var(--green200);
    border-radius: 5px;
    color: var(--white);
    margin-bottom: 1.875rem;
    width: calc(100% - 5rem);
    background-image: url('../img/logo.svg');
    background-position: left top;
    background-repeat: no-repeat;
    .subscribe-textimg-content{
        padding: 1.625rem;
        h1{
            font-size: 3.125rem;
            span{
                font-size: 1rem;
            }
        }
        p{
            color: var(--whitehalf);
        }
    }
    .subscribe-textimg-image{
        margin-top: -1rem;
        margin-right: -5rem;
    }
}
.tabs-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    .tabs-nav{
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        list-style: none;
        border-bottom: 2px solid rgba(188, 188, 188, 0.5);
        .tab-nav-item{
            font-size: 1.125rem;
            font-weight: 400;
            color: var(--black700);
            border-width: 0 0 2px 0;
            border-style: solid;
            border-color: transparent;
            padding: 1.25rem;
            margin-bottom: -1px;
            cursor: pointer;
            &:focus-visible{
                outline: none;
                box-shadow: none;
            }
            &.isActive{
                color: var(--black);
                font-weight: 700;
                border-width: 0 0 2px 0;
                border-style: solid;
                border-color: var(--black);
            }
        }
    }
    .tabs-content{
        margin-top: 1.875rem;
        .tab-panel-content{
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}
.page-title{
    font-size: 1.125rem;
    color: var(--blackhalf);
    font-weight: 400;
    border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);
    padding-bottom: 0.375rem;
    margin-bottom: 2.5rem;
}
.home-page-title-bold{
    font-size: 1.125rem;
    color: var(--black);
    font-weight: bold;
    // padding-bottom: 0.375rem;
    // margin-bottom: 2.5rem;
}
.home-page-title-bold-2{
    font-size: 1.125rem;
    color: var(--black);
    font-weight: bold;  
}
.home-page-box-text{
    font-size: 1.125rem;
    color: var(--black);
    text-align: center;
}
.formBox-inline{
    display: flex;
    align-items: center;
}
.mupload-wrapper{
    color: var(--gray400);
    .mupload-main-wrapper{
        background-color: var(--light500);
        border: 1px dashed var(--gray500);
        border-radius: 5px;
        margin-bottom: 0.625rem;
        .dropzone{
            padding: 3.75rem;
            cursor: pointer;
        }
    }
    &.mupload-secondary-wrapper{
        color: var(--whitehalf);
        .mupload-main-wrapper{
            background-color: var(--bright);
            border-color: #1E5247;
            .dropzone{
                padding: 6.25rem;
            }
        }
    }
}
.Toastify__toast-body {
    text-transform: capitalize;
}
.filterby{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .filterby-lists{
        display: flex;
        align-items: center;
    }
    .dropdown{
        margin-right: 0.75rem;
    }
}
.filterby-main{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.right_block{
    margin-top: -60px;
    padding: 10px;
}
.circle-right-wrapper{
    background-color: var(--white);
    //background-image: url('../images/bg-pattern-circle-3.svg');
    background-position: right top;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.2rem;
    
    
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0.25rem 1.25rem 0;
        margin-bottom: 0.5rem;
        text-align: center;
    }
    .image-element{
        img{
            width: 100%;
        }
    }
}
.categories-button-group{
    display: flex;
    align-items: center;
    overflow-x: auto;
    width: calc(100% + 1.5rem);
    margin-bottom: 3rem;
    .btn{
        white-space: nowrap;
        padding: 0.75rem 1.5rem;
        text-transform: inherit;
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
        + .btn{
            margin-left: 0.75rem;
        }
    }
}
.slider-arrow-up{
    margin-left: 1rem;
    display: flex;
    align-items: center;
}
.Toastify__toast-container{
    z-index: 99999;
}
.bg-primary{
    background-color: var(--primary);
}

.bg-wallet-top{
    
    background-color: var(--primary500);
}


.bg-withdraw-top{
    
    background-color: var(--primary400);
}



.bg-success{
    background-color: var(--success);
}
.bg-danger{
    background-color: var(--dagner);
}
.bg-pending{
    background-color: var(--pending);
}
.bg-light{
    background-color: var(--light100);
}
.bg-dark{
    background-color: var(--black800);
}
.bg-green-cyan{
    background-color: var(--green-cyan100);
}
.bg-green600{
    background-color: var(--green600);
}
.bg-white{
    background-color: var(--white);
}
.borderRadius5px {
    border-radius: 5px;
}
.continuelearningpopup-wrp{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--black);
    padding: 1.875rem;
}
.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}

.thumbnail-main-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .thumbnail-wrapper{
        display: flex;
        flex-direction: column;
        max-width: 206px;
        min-width: 206px;
        .thumbnail-upload-file-wrp{
            width: 100%;
            /*background-color: var(--light800);
            color: var(--blackhalf);
            border: 1px dashed #576562;*/
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            .dropzone{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 8.375rem;
                cursor: pointer;
            }
            .icon{
                color: var(--white);
            }
            .change-video-btn {
                position: absolute;
                bottom: 0;
                right: -11.25rem;
            }
        }
        .thumbnail-upload-wrap{
            background-color: var(--secondary500);
            color: var(--light800half); 
            display: flex;
            flex-direction: column;
            position: relative;
            border-radius: 5px;
            width: 100%;
            height: 8.375rem;
            position: relative;
            cursor: pointer;
            .thumbnail-select-wrap{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: flex-end;
                padding: 0.625rem;
                border: 2px solid var(--primary);
                border-radius: 5px;
            }
            img{
                border-radius: 5px;
                max-width: 100%;
            }
            &.thumbnail-upload-added{
                background-color: transparent;
                height: auto;
            }
        }

    }
  /*  .filesdetails{
        width: calc(100% - 230px);
    }*/
}

.font-size-12{
    font-size: 0.75rem;
}
.font-size-13{
    font-size: 0.8125rem;
}
.homevideomodal-wrapper{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    .homevideomodal-main{
        position: relative;
        .video-replay-details{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--blackhalf);
            color: var(--white);
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .video-replay-details-main{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                max-width: 70%;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 3rem;
                .video-replay-details-text-title{
                    text-transform: uppercase;
                    margin-top: 3rem;
                    margin-bottom: 1.25rem;
                }
                .video-replay-details-text-desc{
                    margin-top: 0;
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
    .homevideomodal-content-bottom{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var( --green300);
        color: var(--inactive);
        padding: 2rem;
        border-radius: 0 0 5px 5px;
    }
}
.search-no-found{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .title{
        margin-bottom: 5rem;
        text-transform: uppercase;
    }
}
.mtb-10{
    margin: 10px 0;
}
.plr-5{
    padding: 0 5px;
}

@media screen and (max-width: 767px) {

    .pop-up-header{
        background-color:var(--primary) !important; 
        height: 100px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .section-tent{
        .tent-slider{
            .video-caption{
                .video-desc{
                    max-width: 90%;
                }
            }
        }
    }
    .font-48{
        font-size: 2rem;
        line-height: 1.2;
    }
    .max-slider{
        max-width: 90%;
        padding-right: 0;
    }
    .rml-24{
        margin-left: -1rem;
        margin-right: -1rem;
        width: calc(100% + 2rem);
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .or{
        span{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .mupload-wrapper{
        .mupload-main-wrapper{
            .dropzone{
                padding: 2.5rem 1.875rem;
                cursor: pointer;
            }
        }
        &.mupload-secondary-wrapper{
            color: var(--whitehalf);
            .mupload-main-wrapper{
                background-color: var(--secondary400);
                border-color: #1E5247;
                .dropzone{
                    padding: 2.5rem 1.875rem;
                }
            }
        }
    }
    .tabs-wrapper{
        .tabs-nav{
            .tab-nav-item{
                font-size: 1rem;
                padding: 1.25rem 0.625rem;
                margin-bottom: -1px;
                cursor: pointer;
                text-align: center;
            }
        }
        .tabs-content{
            margin-top: 1.875rem;
            .tab-panel-content{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }
    .filterby{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1.25rem;
        .filterby-lists{
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .dropdown{
                margin-right: 0px;
                margin-bottom: 0.625rem;
                width: 100%;
            }
            .btn{
                width: 100%;
            }
        }
    }
    .circle-right-wrapper{
        margin-bottom: 1.5rem;
        .content{
            padding: 1.25rem 1.25rem 0;
            margin-bottom: 2.5rem;
        }
        .image-element{
            img{
                width: 100%;
            }
        }
    }

    .section-readyus{
        background-size: 100% auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
        &::after{
            content: '';
            background-size: 100% auto;
        }
    }

    .categories-button-group{
        width: calc(100% + 1rem);
        margin-bottom: 1.25rem;
        .btn{
            padding: 0.625rem 0.9375rem;
            font-size: 1rem;
            margin-bottom: 0px;
            + .btn{
                margin-left: 0.625rem;
            }
        }
    }
    hr{
        width: 100%;
        opacity: 0.2;
        border: 1px solid var(--white);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .swiper-pagination{
        &.swiper-pagination-bullets{
            &.swiper-pagination-horizontal{
                position: static;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }
    }
    .homevideomodal-wrapper{
        .homevideomodal-main{
            .video-replay-details{
                align-items: center;
                .video-replay-details-main{
                    max-width: 100%;
                    padding-bottom: 0rem;
                    .video-replay-details-text-title{
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                        font-size: 0.875rem;
                    }
                    .video-replay-details-text-desc{
                        margin-bottom: 0.5rem;
                        font-size: 0.625rem;
                    }
                }
            }
        }
        .homevideomodal-content-bottom{
            padding: 1rem;
        }
    }
}
.break-all {
    word-break: break-all;
}

.text-cyan{
    color: var(--green-cyan300);
}


.module-img-1{
    width:220px;
    height:140px;
    object-fit:fill;
    position: relative;
    z-index:1;

}
.play-icon-module{
    position: absolute;
    left: 90px;
    top: 50px;
    z-index: 1;
}
.line-height-2{
    line-height: 35px;
}

.btn-profile{
    background-color: var(--primary);
    padding: 5px 25px;
    font-size: 14px;
    font-weight: bold;
}

.pad-10{
    padding: 0.5rem;
}

.pad-20{
    padding: 1rem;
}

.mt1{
    margin-top: 3px;
}

.mt_-20{
    margin-top: -20px;
}



.mt_-15{
    margin-top: -15px;
}

.height-65{
    height:65px;
}

.div-center {
    margin: auto;
    width: 50%;
    text-align: center;
    
  }

  .create_course_image { 
    position: relative; 
    width: 100%; /* for IE 6 */
    clear: both;
    display: contents;
  }
  
  .create_course_h2 { 
    
    z-index: 20;
    color: #fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  .create_course_h2v{
    z-index: 20;
    color:var(--primary);
    font-weight: bold;
    text-align: center;
       width: 100%;
  }
  .icon.create_course_h21.div-center {
    z-index: 99 !important;
    position: absolute;
  }
  .icon.create_course_h2v.div-center {
    z-index: 99 !important;
    position: absolute;
    margin-top: -85px;
  }
  .create_course_image_v {
       display: inline-block;
       position: relative;
    
  }
  .create_course_h21 {
    clear: both;
    z-index: 99;
    position: absolute;
  }
  
.primary{
    background-color: var(--primary);
}
  .watch-next-video-item-duration{
    display: flex;
    justify-content: flex-end;
    background-color: #818181;
    border-radius: 5px;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
}
  .course-upload-wrap{
    background-color: transparent;
    /*color: var(--light800half); */
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;;
      height: 8.375rem;
    
    cursor: pointer;
    .course-select-wrap{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        padding: 0.625rem;
        border: 2px solid var(--primary);
        border-radius: 5px;
    }
    img{
        border-radius: 5px;
        max-width: 100%;
    }
    &.course-upload-added{
        background-color: transparent;
        height: auto;
    }
  }

  .mt_-10{
    margin-top: -10px !important;
  }

  .mt_-20{
    margin-top: -20px !important;
  }

  .mt_-30{
    margin-top: -30px !important;
  }
  .w-5{
      width: 5% !important;
  }
.w-10{
    width: 10% !important;
}
.w-1l0 {
    float: right;
  width: 8%;
  display: inline-table;
  margin-top: -4%;
  }

  .w-15{
    width: 15% !important;
  }
  .x-vis{ overflow-x: visible !important;}

  .w-20{
    width: 20% !important;
  }

  .w-25{
    width: 25% !important;
  }

  .img-fluid-height-150{
    
    // height: 150px;
   
   
  }

  .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #8081ff;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }

  .social-links a:hover {
    background: #4e4c4c;
    color: #fff;
    text-decoration: none;
  }
  
  .div-center {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }


.popup-new-padding {
    padding: 3.7rem;
    margin-left: 0px;
    margin-right: 0px;
  }

.start-meeting-btn{
    padding: 10px;
    margin-left: 5px;
    background-color: var(--primary) !important;
    color: var(--white) !important;
    font-size: 0.7rem;
    text-decoration: none !important;
    text-transform:none;
}


.resechulde-meeting-btn{
    padding: 10px;
    margin-left: 5px;
    background-color: var(--primary200) !important;
    color: var(--black) !important;
    font-size: 0.7rem;
    text-decoration: none !important;
    text-transform:none;
    border: none;
  border-radius: 5px;
}


.resechulde-cancel-btn{
    padding: 10px;
    margin-left: 5px;
    background-color: var(--dagner) !important;
    color: var(--white) !important;
    font-size: 0.7rem;
    text-decoration: none !important;
    text-transform:none;
    border: none;
  border-radius: 5px;
}

.modal_width{
    width: 100%;
}

.bg-sect-col{
    background-color: var(--homeBgColor) !important;
}

.bg-sect-light-grey{
    background-color: var(--light600) !important;
}

.bg-color-prim{
    background-color: var(--primary) !important;
}
.bg-sect-grey{
    background-color: var(--gray8a8a8a) !important;
}

.form-control{
    border-radius: 5px;
}

.info-item {
    padding: 20px;
    background: #ffffff82;
    height: 100%;
    
  }

  .info-item .fa-2x{
    font-size: 40px;
    color: #8081ff !important;
    padding: 5px;
    
}
.faculty-img {
    height: 200px;
    overflow: hidden;
    margin: -30px -30px 15px -30px;
  }


  .sign-form {
    position: relative;
    z-index: 3;
    justify-content: center;
    padding-top: 15%;
    
}
.home-login{
    height: 100vh !important;
}

.formBox {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.courses-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.acco-module {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 100%;
  }


  .change-password{
    background-color: #ffffff;
  }
  .change-password{
    .form-control{
        font-size: 18px !important;
        line-height: 1.5 !important;
    }
    
        .small{
            margin-right: 10px !important;
        }

        // input:focus {
        //     border-color: #4a90e2; /* Change the border color when focused */
        //     outline: none;        /* Remove the default focus outline */
        //     box-shadow: 0 0 5px rgba(74, 144, 226, 0.5); /* Add a subtle shadow */
        // }
  }
.flex-space-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.min-h-50{
    min-height: 50px;
}

@media screen and (max-width: 460px) {

    .dash-guru-status-card{
        height: 90% !important;
    }
    .top-main-boxes{
        padding: 10px;
        margin: 10px;
        width: fit-content;
    }
    .onlineStatus{
        text-align: center !important;
    }
    .live-chat{
        text-align: center;
    }
    .video-poster-bg{
        height: auto !important;
        padding: 10px;
        // margin: 10px;
    }
    .topic-video-card{
        margin-left: 0px !important;
    }
    .video-poster-bg{
        background-color:#8081ff00 !important;
    }
    .topic-interest-group{
        display: none;
    }
    .topic-interest-group{        
        .topic-interest-box{
            max-width: 28% !important;
        }
    }   
    
    section {
        padding: 30px 0 !important;
    
    }
    .footer-top{
        padding: 30px 0 !important;
    }

}