.video-lists-wrapper{
    .video-list-head{
        display: flex;
        align-items: center;
        border-bottom: 2px solid rgba(90, 90, 90, 0.5);
        padding-bottom: 0.625rem;
        .video-list-head-text{
            padding-left: 1rem;
            padding-right: 1rem;
            h4{
                color: var(--gray);
                font-weight: 500;
                font-size: 1.125rem;
                margin: 0;
            }
        }
    }
    .video-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        .video-list-content-main{
            display: flex;
            align-items: flex-start;
            height: calc(100% - 3.75rem);
            border-bottom: 1px solid rgba(90, 90, 90, 0.5);
            padding-top: 1.875rem;
            padding-bottom: 1.875rem;
            .video-list-content-item{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 1rem;
                padding-right: 1rem;
                color: rgba(60, 60, 60, 0.5);
                p{
                    margin: 0;
                }
            }
        }
    }
    .v-w-1{
        width: 20%;
    }
    .v-w-2{
        width: 25%;
    }
    .v-w-3{
        width: 15%;
    }
    .v-w-4{
        width: 10%;
    }
    .v-w-5{
        width: 10%;
    }
    .v-w-6{
        width: 10%;
    }
    .v-w-7{
        width: 10%;
    }
}

@media screen and (max-width: 767px) {
    .video-lists-wrapper{
        .video-list-content{
            .video-list-content-main{
                display: flex;
                align-items: flex-start;
                height: auto;
                border-bottom: 1px solid rgba(90, 90, 90, 0.5);
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
                flex-direction: row;
                flex-wrap: wrap;
                .video-list-content-item{
                    display: flex;
                    padding-left: 0.625rem;
                    padding-right: 0.625rem;
                    margin-bottom: 0.625rem;
                    .vlci-text{
                        color: rgba(208, 207, 207, 1);
                    }
                    &.pl-0{
                        padding-left: 0.625rem !important;
                    }
                    .image-element{
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    p{
                        margin: 0;
                    }
                }
            }
        }
        .v-w-1{
            width: 100%;
        }
        .v-w-2{
            width: 100%;
        }
        .v-w-3{
            width: 50%;
        }
        .v-w-4{
            width: 50%;
        }
        .v-w-5{
            width: 50%;
        }
        .v-w-6{
            width: 50%;
        }
        .v-w-7{
            width: 50%;
        }
    }
}