.nav-lesson{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 1.25rem 0 0;
    list-style: none;
    .lesson-item{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        &:last-child{
            .lesson-item-block{
                .lesson-item-left{
                    .lesson-number{
                        &::after{
                            content: none;
                        }
                    }
                }
            }
        }
        .lesson-item-block{
            display: flex;
           /* align-items: center;*/
            justify-content: flex-start;
            .lesson-item-left{
                position: relative;
                .lesson-number{
                    background-color: var(--blackhalf);
                    border: 2px solid var(--grayhalf);
                    color: var(--white);
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.875rem;
                    font-weight: 700;
                    margin-right: 1.5rem;
                    position: relative;
                    z-index: 2;
                    &::after{
                        content: '';
                        width: 2px;
                        height: 100%;
                        background-color: var(--linehash);
                        position: absolute;
                        top: calc(100% + 2px);
                        z-index: 1;
                    }
                }
            }
            .lesson-item-right{
                width: calc(100% - 3.5625rem);
                overflow: hidden;
                line-height: 1;
                padding-bottom: 1rem;
                span{
                    font-size: 0.625rem;
                    font-weight: 500;
                    color: var(--whitehalf);
                }
                .lesson-title{
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 19px;
                    color: #666;
                    margin-bottom: 0;
                    /*white-space: nowrap;*/
                    text-transform: capitalize;
                }
            }
            &.lesson-watched-done{
                .lesson-item-left{
                    .lesson-number{
                        background-color: var(--success100);
                        border-color:  var(--success100);
                        color: var(--white);
                    }
                }
            }
            &.lesson-watched-half-way{
                .lesson-item-left{
                    .lesson-number{
                        border-color:  var(--orange);
                        background-color: var(--primary);
                    }
                }
            }
            &.lesson-watch-current{
                .lesson-item-left{
                    .lesson-number{
                        background-color: var(--primary);
                        border-color:  var(--primary);
                        color: var(--white);
                    }
                }
                .lesson-item-right{
                    .lesson-title{
                        font-weight: 500;
                        color: #666;
                    }
                }
            }
        }
        .module-text{
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 19px;
            color: var(--primary);
            margin-bottom: 0;
            /*white-space: nowrap;*/
            text-transform: capitalize;
        }

        .lesson-margin{
            padding: 0;
            margin: 0 0 0 0 !important;
        }
    }
}

.nav-module{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 1.25rem 0 0;
    list-style: none;
}