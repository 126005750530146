.topic-interest-group{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1.25rem);
    margin-bottom: 3.125rem;
    margin-left: -0.625rem;
    margin-right: -0.625rem;
    .topic-interest-box{
        max-width: 10%;
    }
}
.topic-interest-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 0.625rem;
    margin: 2%;
    .topic-interest-card{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        cursor: pointer;
        &.selected{
            .image-element{
                img{
                    border-radius: 5px;
                }
            }
            .topic-interest-content{
                background-color: rgba($color: #000000, $alpha: 0.75);
                border-color: var(--primary);
                border-radius: 5px;
            }
        }
        .topic-interest-icon{
            position: absolute;
            right: 0.625rem;
            top: 0.625rem;
        }
        .image-element{
            img{
                width: 100%;
                transition: all ease-in 0.2s;
            }
        }
        .topic-interest-content{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: flex-end;
            text-align:center !important;
            padding: 0.625rem 1.25rem;
           /* background-color: rgba($color: #000000, $alpha: 0.2);*/
            border: 2px solid transparent;
            z-index: 0;
            transition: all ease-in 0.2s;
            .topic-interest-title{
                color: var(--white);
                font-size: 1rem;
                text-align: center;
                text-justify: center;
                font-weight: 700;
                margin-bottom: 0;
                text-align:center !important;
                margin-top:10px
            }
        }
    }
}

.btn-showmore{
    font-size: 13px;
    float: right;
    color: blue;
    cursor: pointer;
}
.btn-showmore:hover{
    font-size: 13px;
    float: right;
    color: red;
}