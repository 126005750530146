.list-step-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4.375rem;
    margin-top: 4.375rem;
    position: relative;
    .list-step{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 33.333%;
        &:last-child{
            h4{
                &::after{
                    content: none;
                }
            }
        }
        h4{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            margin-bottom: 0.9375rem;
            color: var(--white);
            background: linear-gradient(0deg, #1F5D38, #1F5D38), linear-gradient(0deg, #ECCB09, #ECCB09), #ECCB09;
            &::after{
                content: '';
                border-bottom: 2px dashed var(--white);
                height: 2px;
                width: 50%;
                opacity: 0.2;
                position: absolute;
                top: 1.25rem;
                left: calc(100% - 3.125rem);
            }
        }
        p{
            color: var(--whitehalf);
            font-size: 1rem;
            font-weight: 400;
        }
    }
}
@media screen and (max-width: 767px) {
    .list-step-group{
        margin-bottom: 1.875rem;
        margin-top: 1.875rem;
        .list-step{
            text-align: center;
            h4{
                
                &::after{
                    left: calc(100% - 1.5rem);
                }
            }

        } 
    }
}