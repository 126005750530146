.breadcrumb-wrapper{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    h1{
        margin: 0;
        line-height: 1.2;
    }
    .breadcrumb-main{
        display: flex;
        align-items: center;
        font-size: 0.9375rem;
    }
    .breadcrumb-row{
        display: flex;
        align-items: center;
        a{
            color: var(--black);
            cursor: pointer;
            text-decoration: none;
            font-size: 1rem;
            font-weight: 400;
            &.active{
                color: var(--green600);
                pointer-events: none;
                font-weight: 600;
            }
        }
        &:last-child{
            .breadcrumb-arrow-icon{
                display: none;
            }
        }
        .breadcrumb-arrow-icon{
            opacity: 0.2;
            color: var(--draft);
            cursor: inherit;
            display: flex;
            margin: 0 0.625rem;
        }
    }
}