.total-cta-card{
    position: relative;
    padding: 2.25rem 2.4rem;
    background-color:var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
    height: 375px;
    z-index: 2;
    margin-left: 10px;
    margin-right: 10px;
    h4{
        margin-bottom: 0.375rem;
    }
    h1{
        line-height: 1.2;
        margin-bottom: 1.25rem;
    }
    .total-cta-icon{
        position: absolute;
        right: 1.5625rem;
        bottom: 1.5625rem;
        opacity: 0.5;
        color: var(--secondary900);
        z-index: -1;
    }
}
@media screen and (max-width: 767px) {
    .total-cta-card{
        padding: 1.25rem 1.25rem;
        margin-bottom: 1.25rem;
        border-radius: 5px;
        align-items: flex-start;
        h4{
            margin-bottom: 5px;
        }
        h1{
            margin-bottom: 0.625rem;
        }
        .total-cta-icon{
            svg{
                width: 6.25rem;
            }
        }
    }
}