.form-radio-circle{
    display: flex;
    margin-bottom: 1.25rem;
    position: relative;
    flex-wrap: wrap;
    .form-field{
        width: auto;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .form-control{
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    .circle{
        width: 1.5rem;
        height: 1.5rem;
        background-color: var(--white);
        border: 2px solid #D4D4D4;
        border-radius: 50%;
        margin-right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &.circle-active{
            background-color: var(--primary);
            border-color: var(--primary);
            box-shadow: 0px 0px 0px 4px #fff inset;
        }
    }
    .form-radio-label-circle{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: var(--black200);
        width: calc(100% - 3rem);
        margin-top: 0px;
        cursor: pointer;
    }
    &.form-radio-secondary{
        padding: 0.9375rem;
        background-color: var(--white);
        border: 1px solid var(--light700);
        border-radius: 5px;
        &.active{
            border-color: var(--green500);
            background-color: var(--green400);
            .form-radio-label-circle{
                color: var(--black);
                opacity: 1;
                font-weight: 600;
            }
        }
        .form-radio-label-circle{
            opacity: .7;
        }
    }
}