.mcgu-card{
    background-color: var(--bright);
    border-radius: 5px 5px 0 0;
    margin-bottom: 3.75rem;
    margin-top: 0.625rem;
    .mcgu-left{
        display: flex;
        flex-direction: column;
        padding-right: 5rem;
        border-right: 1px solid rgba(231, 231, 231, 0.5);;
        padding: 2.5rem;
        height: 100%;
        .mcgu-top{
            display: flex;
            align-items: center;
            margin-bottom: 1.75rem;
            justify-content: space-between;
            width: 100%;
            .mcgu-top-left{
                display: flex;
                flex-direction: column;
            }
        }
        h1{
            font-size: 3rem;
            margin-bottom: 1.875rem;
            line-height: 1.2;
        }
    }
    .mcgu-right{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 2.5rem;
        justify-content: center;
    }
}
@media screen and (max-width: 767px) {
    .mcgu-card{
        background-color: var(--bright);
        border-radius: 5px 5px 0 0;
        margin-bottom: 1.875rem;
        margin-top: 0.625rem;
        .mcgu-left{
            display: flex;
            flex-direction: column;
            padding-right: 5rem;
            padding: 1.25rem;
            .mcgu-top{
                margin-bottom: 1.25rem;
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: center;
                .mcgu-top-left{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    margin-bottom: 1.25rem;
                }
            }
            h1{
                font-size: 2rem;
                margin-bottom: 1.875rem;
                line-height: 1.2;
            }
        }
        .mcgu-right{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 1.25rem;
            justify-content: center;
        }
    }
}