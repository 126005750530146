.compare-main-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5rem;
    .compare-head-main-wrapper{
        .compare-head-current-plan{
            border-radius: 5px;
            background-color: var(--bright);
            color: var(--white);
            font-weight: 700;
            padding: 0.4375rem 1.5rem;
        }
    }
    .compare-content-main-wrapper{
        .compare-card{
            border-top: 1px solid rgba(203, 203, 203, 0.13);
            padding-top: 2.5rem;
            margin-top: 2.5rem;
            .compare-row{
                &:first-child{
                    .compare-text-left{
                        font-weight: 600;
                        color: var(--white);
                    }
                }
            }
            .compare-row{
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 1.25rem;
                .compare-text-left{
                    font-weight: 400;
                    font-size: 1rem;
                    color: var(--whitehalf);
                }
            }
        }
    }
}