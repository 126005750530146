.profile-dp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .avatar{
        border: 2px solid var(--white);
    }
    .account-status{
        display: flex;
        align-items: center;
        color: var(--light100);
        font-weight: 400;
        margin-top: 0.625rem;
        .icon{
            margin-right: 0.75rem;
            width: 1.25rem;
            height: 1.25rem;
        }
        p{
            margin: 0;
        }
    }
    .profile-dp-details{
        width: calc(100% - 7.75rem);
        p{
            margin: 0;
            line-height: 1.75rem;
        }
        .profile-dp-title{
            font-weight: 500;
            color: var(--black);
        }
        .profile-dp-email,
        .profile-dp-subtitle{
            font-weight: 400;
            color: var(--inactive);
        }
    }
}