.nav-wrp{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid rgba(251, 251, 251, 0.08);
    width: 100%;
    .nav-title{
        color: #000;
        font-size: 0.8125rem;
        font-weight: 400;
        margin-bottom: 0.9375rem;
    }
    .nav{
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        .nav-item{
            margin-bottom: 0.85rem;
            .nav-link{
                text-decoration: none;
                color: var(--navinactive);
                font-weight: 400;
                font-size: 1rem;
                display: flex;
                align-items: center;
                .nav-link-icon{
                    margin-right: 1.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &:hover{
                    color: var(--white);
                    background-color: #8081ff;
                }
                &.active{
                    font-weight: 700;
                    color: var(--primary);
                    background-color: #f6f6ff;
                    border-radius: 4px;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

}
@media screen and (max-width: 767px) {
    .nav-wrp{
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        .nav{
            .nav-item{
                margin-bottom: 1rem;
                .nav-link{
                    .nav-link-icon{
                        margin-right: 1.25rem;
                    }
                }
            }
        }
    
    }    
}