.video-players-details{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.375rem;
    background-color: var(--white);
    color: var(--black);
    margin-bottom: 1.875rem;    
    .vpd-upload-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.875rem;
        .vpd-upload-info-left{
            width: calc(100% - 142px);
            .category-title{
                color: var(--blackhalf);
                font-weight: 700;
            }
            .title{
                line-height: 1.2;
                margin-bottom: 0.625rem;
            }
        }
        .vpd-upload-info-right{
            .btn{
                font-size: 1.125rem;
                text-transform: inherit;
            }
        }
    }
    .vpd-upload-channel-info{
        margin-bottom: 2.5rem;
    }
    .vpd-views-date-time{
        display: contents;
        align-items: center;
        font-size: 0.875rem;
        color: var(--blackhalf);
        .vdt{
            display: flex;
            align-items: center;
            margin-right: 0.9375rem;
            span{
                margin-left: 0.5rem;
            }
        }
    }
    .vpd-upload-channel-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .vpd-dp-heading{
            display: flex;
            align-items: center;
            .avtar{
                margin-right: 0.9375rem;
            }
            h4{
                margin-bottom: 0;
            }
            .vpd-dp-details{
                display: flex;
                flex-direction: column;
                margin-left: 0.9375rem;
                .vpd-dp-details-favourite-video-text{
                    font-size: 1rem;
                    margin-left: 0.75rem;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .video-players-details{
        padding: 1rem;
        margin-bottom: 1.25rem;
        .vpd-upload-info{
            margin-bottom: 1rem;
            .vpd-upload-info-left{
                width: calc(100% - 50px);
                .category-title{
                }
                .title{
                    font-size: 1.25rem;
                }
            }
            .vpd-upload-info-right{
                .btn{
                    flex-direction: column;
                    font-size: 1rem;
                }
            }
        }
        .vpd-upload-channel-info{
            margin-bottom: 1rem;
        }
        .vpd-views-date-time{
            .vdt{
                display: flex;
                align-items: center;
                margin-right: 0.9375rem;
                span{
                    margin-left: 0.5rem;
                }
            }
        }
        .vpd-upload-channel-info{
            .vpd-dp-heading{
                .avtar{
                    margin-right: 0.9375rem;
                }
                .vpd-dp-details{
                    margin-left: 0.5rem;
                    .vpd-dp-details-favourite-video-text{
                        font-size: 0.75rem;
                        margin-left: 0.5rem;
                    }
                }
            }
            .btn{
                // font-size: 0.75rem;
            }
        }
    }    
}