.dp-wrapper{
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
    position: relative;
    height: 100%;
    .dp-heading{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .dp-details{
        margin-left: 0.75rem;
        display: flex;
        flex-direction: column;
        h4{
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 0;
        }
        P{
            font-size: 1.2875rem;
            margin: 0;
        }
    }
    .dp-dropdown{
        position: absolute;
        right: 0;
        top: 100%;
        border: 1px solid rgba(152, 148, 148, 0.5);
        background-color: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 100%;
        min-width: 210px;
        .nav-wrp{
            padding: 0;
            margin: 0;
            .nav{
                margin: 0;
                padding: 0;
                border: 0;
                .nav-item{
                    margin: 0;
                    .nav-link{
                        width: 100%;
                        padding: 0.625rem 1.875rem;
                        color: var(--inactive);
                        &:hover{
                            background-color: var(--inactive);
                            color:var(--white);
                        }
                    &:last-child{
                        border-top: 1px solid rgba(152, 148, 148, 0.5);
                    }
                }
            }
        }
    }
}
}
