.heroslider{
  .hero-item{
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      top: -10px;
      .hero-image{
          img{
              width: 100%;
          }
      }
      .hero-video{
          padding-top: 36.25%;
          height: 0px;
          position: relative;
          iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
          }
      }
      .video-js{
          .vjs-big-play-button{
              display: none;
          }
      } 
      .hero-content{
          position: absolute;
          bottom: 3.75rem;
          width: 100%;
      }
      .hero-caption{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          h1{
              font-size: 3.25rem;
              line-height: 62px;
              font-weight: 600;
              margin-bottom: 0;
              color: var(--white);
              max-width: 50%;
              text-transform: uppercase;
          }
      }
  }
}
@media screen and (max-width: 767px) {
  .heroslider{
      .hero-item{
          .hero-content{
              /*bottom: 0.9375rem;
              top: 0.9375rem;*/
              display: flex;
              align-items: center;
              position: static;
              background-color: var(--secondary100);
              padding: 20px 0;
          }
          .hero-caption{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              h1{
                  font-size: 2rem;
                  line-height: 1.2;
                  max-width: 100%;
                  margin-bottom: 1.25rem;
              }
          }
      }
  }
}
