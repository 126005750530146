.nav-tab-panel-wrp{
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.875rem;
    .nav-wrp{
        padding: 0;
        margin: 0;
        .nav{
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            .nav-item{
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                margin: 0;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
                .nav-link{
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    color: #6D6D6D;
                    padding: 7px 0 0.625rem;
                    border-bottom: 3px solid transparent;
                    &.active{
                        color: var(--primary);
                        font-weight: 500;
                        border-color: var(--primary);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .nav-tab-panel-wrp{
        .nav-wrp{
            .nav{
                .nav-item{
                    padding-left: 0.625rem;
                    padding-right: 0.625rem;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    .nav-link{
                        font-size: 1rem;
                        line-height: 1.5rem;
                        &.active{
                            color: var(--primary);
                            font-weight: 500;
                            border-color: var(--primary);
                        }
                    }
                }
            }
        }
    }
}